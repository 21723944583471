<template>
    <div class=" clubinfoedit user-details">
        <div class="">
            <!--header-->
            <div class="profile-header profile-bg">
                <div class="row">
                  <div v-if="isProfileloading==true" class="d-flex justify-content-center userpf-loader">
                    <div  class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                    <div class="col-md-2 text-right hovereffect pl-5 pr-0 mr-0">
                        <img style="height:155px;width:100%;" :src="profileImg" alt="User Avatar"   class="mr-3 avatar">
                        <div class="overlay">
                            <!-- <b-button class="badge text" v-b-modal.profile_pic_modal>
                                <i class="fas fa-upload"></i> Upload Image
                            </b-button> -->
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="profiler-detail pl-4 pr-4">
                            <h3>{{clubuserProfileData.club_name}}</h3>
                          <div class="d-flex">
                            <h5 v-if="subdomain == 'waterski'">{{ clubuserProfileData.primary_type_name }}</h5>
                            <h5 v-else>{{ clubuserProfileData.club_type }}</h5>
                          <div
                              class="right"
                              style="margin-left: auto; align-items: center;"
                          >
                            <div class="autorenewal" v-if="getSiteProps('general.club_auto_renewal_option')">
                    <span class="text-white">
                      <b-form-checkbox
                          v-model="autoRenew"
                          name="autorenewal"
                          switch
                          value="1"
                          size="lg"
                          @change="changeAutoRenewal($event)"
                          v-if="subdomain !== 'shooting'"
                      >
                        Auto Renewal</b-form-checkbox
                      ></span
                    >
                            </div>
                          </div>
                            </div>
                            <div class="b-der"></div>
                            <div class="d-flex">
                                <div class="reg_date mt-3 mr-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                            <img src="/dist/img/profile/ico-registerd-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span class="lable">Registered Date</span>
                                            <span> {{regDate || "N/A"}}</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="ex_date mt-3 ml-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                             <img src="/dist/img/profile/ico-expired-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span  class="lable">Expiration Date</span>
                                            <span> {{expiryDate || "N/A"}} <ChangeRegExpirationDate v-if="getSiteProps('general.change_expiry_date')" :memberType="'club'" :memId="regId" :updateExpireDate="expiryDate" @reload="displayAlertAndReloadData($event)"></ChangeRegExpirationDate> </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4 ml-5">
                                  <div class="d-flex align-items-center">
                                    <h5 class=" text-right d-flex"  style="align-items: center;" v-if="userNote.notes && userNote.notes.length > 0">
                                      <a href="#notes_section" class="text-white"><i class="text-success fa fa-check"></i> Notes</a><span class="lable text-white mr-2" v-b-modal.note-modal variant="light" v-on:click="addnote()">&nbsp; <i class="fas fa-plus-circle"></i></span>
                                    </h5>
                                    <h5 class="text-white text-right d-flex"  style="align-items: center;" v-else>
                                      <span class="lable text-white mr-2" v-b-modal.note-modal variant="light" v-on:click="addnote()"><i class="fa fa-sticky-note"></i> Add Note</span>
                                    </h5>
                                  </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pl-0 pr-5">
                         <div class="mcard">
                            <club-card :club-id="clubuserProfileData.club_uid" :key="updateLoggedUserInfo"></club-card>
                         </div>
                         <div class="text-center mt-3">
                            <a href="javascript:void(0)" @click="refreshCard" class="refreshCardLink">
                              <i class="fas fa-redo" :class="refreshProgress ? 'fa-spin' : ''"></i> Refresh Card
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <!--end header-->

            <!-- profile body start-->
            <div class="profile-body mt-4">
              <custom-alert id="alert_box" v-if="displayAlert == true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>
              <div v-if="isProfileloading==true"  class="d-flex justify-content-center userpf-loader">
                <div  class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
                <div class="row">
                    <div class="col-md-6 ">
                        <div class="card">
                          <div class="card-header container-fluid">
                            <div class="row">
                              <div class="col-md-5">
                                <h3 v-if="prop_fields && prop_fields.club_info">{{ prop_fields.club_info.tab_title || 'Club Information'}}</h3>
                              </div>
                              <div class="col-md-7 float-right">
                                <button v-b-modal.modal-center1 class="btn btn-outline-primary btn-sm float-right ml-1"  ><span class="fa fa-edit"></span> Edit</button>
                                <button class="btn btn-outline-primary btn-sm float-right" @click="impersonate" ><span class="fa fa-user-secret"></span> Login as {{clubuserProfileData.club_uid || "N/A"}}</button>

                              </div>
                            </div>
                          </div>

                            <div class="card-body">
                              <custom-alert v-if="displayMissingMemFields==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                                <div class="row" v-if="prop_fields && prop_fields.club_info">
                                    <div class="col-md-6 " v-if="prop_fields.club_info && prop_fields.club_info.clubname">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.clubname.label || 'Club Name'}}</label>
                                            <span class="title">{{clubuserProfileData.club_name || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label" v-if="prop_fields.club_info.clubMembership != undefined">{{ prop_fields.club_info.clubMembership.label ||'Team Membership '}}#</label>
                                            <label class="form-label" v-else>Club Membership #</label>
                                            <span class="title">{{clubuserProfileData.club_uid || "N/A"}}</span>
                                        </div>
                                    </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="form-label">Registered Date</label>
                                      <span class="title">{{regDate || "N/A"}}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="form-label">Expiration Date</label>
                                      <span class="title">{{ expiryDate || "N/A"}}</span>
                                    </div>
                                  </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.primary_club_type">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.primary_club_type.label || 'Primary Club Type'}}</label>
                                            <span class="title">{{ clubuserProfileData.primary_type_name || "N/A" }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.secondary_club_type">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.secondary_club_type.label || 'Secondary Club Type'}}</label>
                                            <span class="title">{{ clubuserProfileData.secondary_type_name || "NP - No Preference" }}</span>
                                            <!-- <span>{{ 'test' }}</span> -->
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_website">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.club_website.label || 'Club Website'}}</label>
                                           <span class="title">{{clubuserProfileData.website || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.email">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.email.label || 'Club Email'}}</label>
                                           <span class="title">{{clubuserProfileData.email || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.include_directory">
                                        <div class="form-group">
                                            <label class="form-label">{{ prop_fields.club_info.include_directory.label || 'Include in online club directory'}}</label>
                                            <span class="title">{{ clubuserProfileData.exclude?"Yes":"No" }}</span>
                                        </div>
                                    </div>
                                  <div  :class="clubAdditionalInfoView ?'col-md-4':'col-md-6'">
                                    <div class="form-group">
                                      <label class="form-label">Membership Status</label>
                                      <span class="text-success" v-if="!isExpired && clubuserProfileData.registration != undefined && clubuserProfileData.registration.reg_status == 'Active'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Current</span>
                                      <span class="text-warning" v-else-if="!isExpired && clubuserProfileData.registration != undefined"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubuserProfileData.registration.reg_status || 'N/A' }}</span>
                                      <span class="text-warning" v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Expired</span>
                                    </div>
                                  </div>
                                    <div class="col-md-6 " v-if="prop_fields.club_info && prop_fields.club_info.club_address">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.club_address.label || 'Mailing Address'}}</label>
                                            <span class="title">{{ clubuserProfileData.address || "N/A" }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 " v-if="prop_fields.club_info && prop_fields.club_info.club_address2">
                                      <div class="form-group">
                                        <label class="form-label">{{prop_fields.club_info.club_address2.label || 'Mailing Address'}}</label>
                                        <span class="title">{{ clubuserProfileData.address2 || "N/A" }}</span>
                                      </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_city">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.club_city.label || 'City'}}</label>
                                            <span class="title">{{ clubuserProfileData.city || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Country</label>
                                            <span class="title">{{ clubuserProfileData.country || "N/A"}}</span>
                                        </div>
                                    </div> -->
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_state">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.club_state.label || 'State'}}</label>
                                            <span class="title">{{ clubuserProfileData.state_code || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_zip">
                                        <div class="form-group">
                                            <label class="form-label">{{prop_fields.club_info.club_zip.label || 'Zip'}}</label>
                                            <span class="title">{{ clubuserProfileData.zip || "N/A"}}</span>
                                        </div>
                                    </div>

                                  <div class="col-md-6" v-if="clubAdditionalInfoView">
                                    <div class="form-group">
                                      <label class="form-label">Region</label>
                                      <span class="title">{{ clubuserProfileData.region || "N/A"}}</span>
                                    </div>
                                  </div>
                                  <!-- Alliance fastpitch -->
                                  <div class="col-md-6 " v-if="!clubAdditionalInfoView">
                                    <div class="form-group">
                                      <label class="form-label">{{ prop_fields.club_info.member_league.label }}</label>
                                      <span class="title">{{ clubuserProfileData.memberLeageue.name || "N/A"}}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-6 " v-if="!clubAdditionalInfoView">
                                    <div class="form-group">
                                      <label class="form-label">{{ prop_fields.club_info.age_division.label }}</label>
                                      <span class="title" v-if="clubuserProfileData.ageDivision != null">{{ clubuserProfileData.ageDivision.name || "N/A"}}</span>
                                      <span class="title" v-else>N/A</span>
                                    </div>
                                  </div>
                                  <div class="col-md-6 " v-if="!clubAdditionalInfoView">
                                    <div class="form-group">
                                      <label class="form-label">{{ prop_fields.club_info.agl_team.text }}</label>
                                      <span class="title">{{ clubuserProfileData.agl_team_id || "N/A"}}</span>
                                    </div>
                                  </div>
                                  <!-- End -->
                                    <!-- Shooting info -->
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_visible_type">
                                      <div class="form-group">
                                        <label class="form-label">{{prop_fields.club_info.club_visible_type.label || 'Type'}}</label>
                                        <span class="title">{{ clubuserProfileData.visibility_type || "N/A"}}</span>
                                      </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_type">
                                      <div class="form-group">
                                        <label class="form-label">{{prop_fields.club_info.club_type.label || 'Club Type'}}</label>
                                        <span class="title">{{ clubuserProfileData.club_type || "N/A"}}</span>
                                      </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_admin_member_id">
                                      <div class="form-group">
                                        <label class="form-label">{{'Club Admin Member ID'}}</label>
                                        <span class="title">{{ clubuserProfileData.club_admin_member_id || "N/A"}}</span>
                                      </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_admin_name">
                                      <div class="form-group">
                                        <label class="form-label">{{prop_fields.club_info.club_admin_name.label || 'Club Admin Name'}}</label>
                                        <span class="title">{{ clubuserProfileData.club_admin_name || "N/A"}}</span>
                                      </div>
                                    </div>
                                    <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_discipilines">
                                      <div class="form-group">
                                        <label class="form-label">{{prop_fields.club_info.club_discipilines.label || 'Club Disciplines'}}</label>
                                        <ul v-if="clubuserProfileData.club_disciplines" class="pl-3">
                                          <li v-for="(item, k) in clubuserProfileData.club_disciplines" :key="'club_dis_'+k">
                                            {{ item.name }}
                                          </li>
                                        </ul>
                                        <span class="title" v-else>N/A</span>
                                      </div>
                                    </div>
                                    <!-- Shooting info -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div class="card" v-if="(prop_fields_contact && prop_fields_contact.search_by_member) || (prop_fields_contact && prop_fields_contact.search_by_name)">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>{{prop_fields_contact.title || 'Primary Contact Information'}}</h3>
                                </div>
                                <div class="modal-btn">
                                    <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('0')">
                                        <span class="fa fa-edit"></span> Edit
                                    </b-button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">First Name</label>
                                             <span class="title" v-if="clubuserProfileData.primary_contact != null">{{ clubuserProfileData.primary_contact.first_name || "N/A" }}</span>
                                             <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Last Name</label>
                                            <span class="title" v-if="clubuserProfileData.primary_contact != null">{{ clubuserProfileData.primary_contact.last_name || "N/A"}}</span>
                                            <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="form-group">
                                            <label class="form-label">Member #</label>
                                            <span class="title" v-if="clubuserProfileData.primary_contact != null">{{ clubuserProfileData.primary_contact['membership_id'] || "N/A" }}</span>
                                            <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">City</label>
                                             <span class="title" v-if="clubuserProfileData.primary_contact != null">{{ clubuserProfileData.primary_contact.city || "N/A" }}</span>
                                            <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">State</label>
                                             <span class="title" v-if="clubuserProfileData.primary_contact != null">{{ clubuserProfileData.primary_contact.state_code || clubuserProfileData.state_code || "N/A" }}</span>
                                            <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="form-group">
                                            <label class="form-label">Zip Code</label>
                                             <span class="title" v-if="clubuserProfileData.primary_contact != null">{{ clubuserProfileData.primary_contact['zip'] || "N/A" }}</span>
                                            <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="form-group">
                                            <label class="form-label">Phone</label>
                                            <span class="title" v-if="clubuserProfileData.primary_contact && clubuserProfileData.primary_contact != null">{{ clubuserProfileData.primary_contact.phone_1 || "N/A" }}</span>
                                            <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Email</label>
                                              <span class="title" v-if="clubuserProfileData.primary_contact && clubuserProfileData.primary_contact != null"> <a class="email-link" v-bind:href="'mailto:' + clubuserProfileData.primary_contact.email ">{{ clubuserProfileData.primary_contact.email || "N/A" }}</a></span>
                                            <span class="title" v-else>N/A</span>
                                        </div>
                                    </div>

                                  <div  class="col-md-6" v-if="clubuserProfileData.primary_contact != null">
                                    <div class="form-group">
                                      <label class="form-label">Membership Status <span v-if="clubuserProfileData.primary_contact.member.status_text" v-b-tooltip.hover :title="clubuserProfileData.primary_contact.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                                      <span class="text-success" v-if="clubuserProfileData.primary_contact.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.primary_contact.member.status_text }} (Expires {{ clubuserProfileData.primary_contact.member.valid_thru | formatDob }})</span>
                                      <span class="text-warning"  v-else-if="clubuserProfileData.primary_contact.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubuserProfileData.primary_contact.member.status_text }}</span>
                                      <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubuserProfileData.primary_contact.member.status_text }}</span>
                                    </div>
                                  </div>
                                  <template v-if="clubuserProfileData.primary_contact != null">
                                    <div class="col-md-6" v-if="clubuserProfileData.primary_contact.member.member_type_id != 2">
                                      <div class="form-group">
                                        <label class="form-label">Waiver Status</label>
                                        <span class="text-success" v-if="clubuserProfileData.primary_contact.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed (Expires {{clubuserProfileData.primary_contact.member.valid_thru | formatDob }})</span>
                                        <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                                      </div>
                                    </div>
                                  </template>

                                  <template v-if="subdomain == 'waterski' && clubuserProfileData.primary_contact != null">
                                    <div  class="col-md-6">
                                      <div class="form-group" v-if="clubuserProfileData.primary_contact.member.safe_sport_screening_compliance == true">
                                        <label class="form-label" >Safe Sport Training Status</label>
                                        <span class="text-success" v-if="clubuserProfileData.primary_contact.member.safe_sport != null && clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status_text}} (Expires {{ clubuserProfileData.primary_contact.member.safe_sport.expiration }})</span>
                                        <span class="text-warning" v-else-if="clubuserProfileData.primary_contact.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status == 13 && clubuserProfileData.primary_contact.member.safe_sport.expiration"><br>(On {{ clubuserProfileData.primary_contact.member.safe_sport.expiration }})</template></span>
                                        <span class="text-warning" v-else>Not Started</span>
                                      </div>
                                      <div class="form-group" v-else>
                                        <label class="form-label">Safe Sport Training Status</label>
                                        <span class="">N/A</span>
                                      </div>
                                    </div>
                                    <div  class="col-md-6">
                                      <div class="form-group" v-if="clubuserProfileData.primary_contact.member.safe_sport_screening_compliance == true">
                                        <label class="form-label">Background Screen Status <span v-if="clubuserProfileData.primary_contact.member.background_screening != null && clubuserProfileData.primary_contact.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.primary_contact.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                                        <span class="text-success" v-if="clubuserProfileData.primary_contact.member.background_screening != null && clubuserProfileData.primary_contact.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.primary_contact.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.primary_contact.member.background_screening.expiration | formatDob }})</span>
                                        <span class="text-warning" v-else-if="clubuserProfileData.primary_contact.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.primary_contact.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.primary_contact.member.background_screening.status == 6 && clubuserProfileData.primary_contact.member.background_screening.expiration"><br>(On {{ clubuserProfileData.primary_contact.member.background_screening.expiration | formatDob }})</template></span>
                                        <span class="text-warning" v-else>Not Started</span>
                                      </div>
                                      <div class="form-group" v-else>
                                        <label class="form-label">Background Screen Status</label>
                                        <span class="">N/A</span>
                                      </div>
                                    </div>
                                    <div  class="col-md-6">
                                      <div class="form-group" v-if="clubuserProfileData.primary_contact.member.safe_sport_screening_compliance == true">
                                        <label class="form-label">MVR Status <span v-if="clubuserProfileData.primary_contact.member.background_screening != null && clubuserProfileData.primary_contact.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.primary_contact.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                                        <span class="text-success" v-if="clubuserProfileData.primary_contact.member.background_mvr_review != null && clubuserProfileData.primary_contact.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.primary_contact.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.primary_contact.member.background_mvr_review.expiration | formatDob }})</span>
                                        <span class="text-warning" v-else-if="clubuserProfileData.primary_contact.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.primary_contact.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.primary_contact.member.background_mvr_review.status == 6 && clubuserProfileData.primary_contact.member.background_mvr_review.expiration"><br>(On {{ clubuserProfileData.primary_contact.member.background_mvr_review.expiration | formatDob }})</template></span>
                                        <span class="text-warning" v-else>Not Started</span>
                                      </div>
                                      <div class="form-group" v-else>
                                        <label class="form-label">MVR Status</label>
                                        <span class="">N/A</span>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                            </div>
                        </div>

                        <div class="card" v-else>
                          <div class="card-header">
                            <div class="header-title">
                              <h3>{{(prop_fields_contact && prop_fields_contact.tab_title) || 'Contact Information'}}</h3>
                            </div>
                            <div class="modal-btn">
                              <b-button
                                v-b-modal.edit-club-contact
                              >
                                <span class="fa fa-edit"></span> Edit
                              </b-button>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12 col-lg-12" v-if="!isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.email">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.email.label || 'E-Mail'}}</label>
                                  <span class="title">{{
                                    clubuserProfileData.primary_contact.email || "N/A"
                                  }}</span>
                                </div>
                                <hr>
                              </div>
                              <div class="col-md-12 col-lg-12" v-if="!isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.club_address">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.club_address.label || 'Mailing Address'}}</label>
                                  <span class="title">{{
                                    clubuserProfileData.primary_contact.address1 || "N/A"
                                  }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.club_city">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.club_city.label || 'City'}}</label>
                                  <span class="title">{{
                                    clubuserProfileData.primary_contact.city || "N/A"
                                  }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.club_state">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.club_state.label || 'State'}}</label>
                                  <span class="title">{{
                                    clubuserProfileData.primary_contact.state_code || clubuserProfileData.state_code || "N/A"
                                  }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4 " v-if="!isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.club_zip">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.club_zip.label || 'Zip Code'}}</label>
                                  <span class="title">{{
                                    clubuserProfileData.primary_contact["zip"] || "N/A"
                                  }}</span>
                                </div>
                              </div>
                              <!-- Alliance FastPitch -->
                              <div class="col-md-12 col-lg-12" v-if=" isAllianceFastPitch && prop_fields_contact && prop_fields_contact.email">
                                <div class="row">
                                <div class="form-group col-md-6 col-md-4">
                                  <label class="form-label">Head Coach Name</label>
                                  <span class="title">{{
                                      clubuserProfileData.team_head_coach || "N/A"
                                    }}</span>
                                </div>
                                <div class="form-group col-md-6 col-md-4">
                                  <label class="form-label">{{prop_fields_contact.email.label || 'E-Mail'}}</label>
                                  <span class="title">{{
                                      clubuserProfileData.email || "N/A"
                                    }}</span>
                                </div>
                                </div>
                                <hr>
                              </div>
                              <div class="col-md-12 col-lg-12" v-if=" isAllianceFastPitch && prop_fields_contact && prop_fields_contact.address1">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.address1.label || 'Mailing Address'}}</label>
                                  <span class="title">{{
                                      clubuserProfileData.address || "N/A"
                                    }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if=" isAllianceFastPitch && prop_fields_contact && prop_fields_contact.city">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.city.label || 'City'}}</label>
                                  <span class="title">{{
                                      clubuserProfileData.city || "N/A"
                                    }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if=" isAllianceFastPitch && prop_fields_contact && prop_fields_contact.country">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.country.label || 'City'}}</label>
                                  <span class="title">{{
                                      clubuserProfileData.country || "N/A"
                                    }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="isAllianceFastPitch && prop_fields_contact && prop_fields_contact.state_code">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.state_code.label || 'State'}}</label>
                                  <span class="title">{{
                                      clubuserProfileData.state_code || "N/A"
                                    }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4 " v-if=" isAllianceFastPitch && prop_fields_contact && prop_fields_contact.zip">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.zip.label || 'Zip Code'}}</label>
                                  <span class="title">{{
                                      clubuserProfileData.zip || "N/A"
                                    }}</span>
                                </div>
                              </div>
                              <!-- End -->
                              <template v-if=" clubuserProfileData.primary_contact && prop_fields_contact &&  clubuserProfileData.primary_contact.physical_address">
                                <div class="col-md-12 col-lg-12" v-if="prop_fields_contact.club_physical_address">
                                  <hr>
                                  <div class="form-group">
                                    <label class="form-label">{{prop_fields_contact.club_physical_address.label || 'Physical Address'}}</label>
                                    <span class="title">{{
                                      clubuserProfileData.primary_contact.physical_address || "N/A"
                                    }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4" v-if="prop_fields_contact && prop_fields_contact.club_physical_city">
                                  <div class="form-group">
                                    <label class="form-label">{{prop_fields_contact.club_physical_city.label || 'City'}}</label>
                                    <span class="title">{{
                                      clubuserProfileData.primary_contact.physical_city || "N/A"
                                    }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4" v-if="prop_fields_contact && prop_fields_contact.club_physical_state">
                                  <div class="form-group">
                                    <label class="form-label">{{prop_fields_contact.club_physical_state.label || 'State'}}</label>
                                    <span class="title">{{
                                      clubuserProfileData.primary_contact.physical_state_code || clubuserProfileData.primary_contact.physical_state || "N/A"
                                    }}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4 " v-if="prop_fields_contact && prop_fields_contact.club_physical_zip">
                                  <div class="form-group">
                                    <label class="form-label">{{prop_fields_contact.club_physical_zip.label || 'Zip Code'}}</label>
                                    <span class="title">{{
                                      clubuserProfileData.primary_contact.physical_zip || "N/A"
                                    }}</span>
                                  </div>
                                </div>
                              </template>
                              <div class="col-md-12">
                                <hr>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.mobilephone">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.mobilephone.label || 'Phone'}}</label>
                                  <span class="title">{{
                                    clubuserProfileData.primary_contact.phone_1 || "N/A"
                                  }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.mobilephone">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.homephone.label || 'Phone'}}</label>
                                  <span class="title">{{
                                    clubuserProfileData.phone || "N/A"
                                  }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="isAllianceFastPitch && clubuserProfileData.primary_contact && prop_fields_contact && prop_fields_contact.homephone">
                                <div class="form-group">
                                  <label class="form-label">{{prop_fields_contact.mobilephone.label || 'Phone'}}</label>
                                  <span class="title">{{
                                      clubuserProfileData.phone_2 || "N/A"
                                    }}</span>
                                </div>
                              </div>
                                <template v-if="clubuserProfileData.primary_contact != null && !isAllianceFastPitch">
                                  <div  class="col-md-6">
                                    <div class="form-group">
                                      <label class="form-label" >Safe Sport Training Status</label>
                                      <span class="text-success" v-if="clubuserProfileData.primary_contact.member.safe_sport != null && clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status_text}} (Expires {{ clubuserProfileData.primary_contact.member.safe_sport.expiration }})</span>
                                      <span class="text-warning" v-else-if="clubuserProfileData.primary_contact.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubuserProfileData.primary_contact.member.safe_sport.safe_sport_status == 13 && clubuserProfileData.primary_contact.member.safe_sport.expiration"><br>(On {{ clubuserProfileData.primary_contact.member.safe_sport.expiration }})</template></span>
                                      <span class="text-warning" v-else>Not Started</span>
                                    </div>
                                  </div>
                                  <div  class="col-md-6">
                                    <div class="form-group">
                                      <label class="form-label">Background Screen Status <span v-if="clubuserProfileData.primary_contact.member.background_screening != null && clubuserProfileData.primary_contact.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.primary_contact.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                                      <span class="text-success" v-if="clubuserProfileData.primary_contact.member.background_screening != null && clubuserProfileData.primary_contact.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.primary_contact.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.primary_contact.member.background_screening.expiration | formatDob }})</span>
                                      <span class="text-warning" v-else-if="clubuserProfileData.primary_contact.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.primary_contact.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.primary_contact.member.background_screening.status == 6 && clubuserProfileData.primary_contact.member.background_screening.expiration"><br>(On {{ clubuserProfileData.primary_contact.member.background_screening.expiration | formatDob }})</template></span>
                                      <span class="text-warning" v-else>Not Started</span>
                                    </div>
                                  </div>
                                </template>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

                 <div class="row profile-body" v-if="subdomain == 'waterski'">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Site Information</h3>
                                </div>
                                <div class="modal-btn">
                                     <b-button @click="$bvModal.show('ski_sites_modal');setVal('Add Ski-Sites',0)" style="width: 100px">
                                        <span class="fa fa-edit"></span> Add
                                    </b-button>
                                </div>
                            </div>
                            <div class="card-body pt-0 table-responsive">
                                <b-table id="ski-site-table"
                                    :items="clubuserProfileData.team_sites"
                                    :fields="tblSiteFields"
                                    class="mt-3"
                                    hover
                                    show-empty
                                >
                                    <template :fields="items"></template>
                                    <template #empty="scope">
                                        <h6>{{ scope.emptyText }}</h6>
                                    </template>
                                    <template #cell(actions)="row">
                                        <div class="text-left py-0 align-middle">
                                            <div class="btn-group btn-group-sm">
                                                <button class="btn btn-primary ml-1" @click="$bvModal.show('ski_sites_modal');setVal('Update Ski-Sites',row.item.id)"><i
                                                    class="fas fa-pencil-alt"></i></button>
                                                <button class="btn btn-danger ml-1" @click="delTeamSites(row.item.id)"><i
                                                    class="fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
              <div class="row profile-body" v-if="subdomain == 'waterski'">
                <div class="col-md-12">
                  <div class="card border-none">
                    <div class="card-header bg-white">
                      <div class="row">
                        <div class="col-md-9">
                          <div class="header-title">
                            <h3>Certificate(s) of Insurance</h3>
                          </div>
                        </div>
                        <div class="col-md-3 pl-5">
                          <b-form-checkbox
                              v-model="viewInsurancePrevious"
                              switch
                              size="lg"
                              :checked="viewInsurancePrevious"
                              @change="changeInsuranceViewPrevious($event)"
                          ><small>View Previous Years</small></b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      <b-table
                          id="my-table"
                          :items="insuranceList"
                          :fields="tblInsuredFields"
                          sort-by="transaction_time"
                          sort-desc="Descending"
                          class="mt-3"
                          hover
                          show-empty
                          :bind="'insuranceListKey-' + insuranceListKey"
                      >
                        <template #empty="scope">
                          <h4>{{ scope.emptyText }}</h4>
                        </template>
                        <template #cell(actions)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-primary ml-1" @click="viewRow(row.item, row.index, $event.target)">
                                <i class="fas fa-eye"></i></button>
                              <button class="btn btn-primary ml-1" @click="editRow(row.item, row.index, $event.target)">
                                <i class="fas fa-pencil-alt"></i></button>
                              <button class="btn btn-danger ml-1" @click="deleteInsurance(row.item.id)"><i
                                  class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #cell(fulfilled)="row">
                          <b-form-checkbox v-model="row.item.fulfilled" checked="true" name="check-button" value="1" unchecked-value="0"
                                           @change="updateFulfilled(row.item.id, row.item.fulfilled)"></b-form-checkbox>
                        </template>
                        <!--<template #table-busy>
                            <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                            </div>
                        </template>-->
                      </b-table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                  <!-- /.card -->
                </div>
                <div class="col-md-12">
                  <div class="card border-none">
                    <div class="card-header bg-white">
                      <div class="row">
                        <div class="col-md-9">
                          <div class="header-title">
                            <h3>Practice/Exhibition Sanctioning</h3>
                          </div>
                        </div>
                        <div class="col-md-3 pl-5">
                          <b-form-checkbox
                              v-model="viewSanctionPrevious"
                              switch
                              size="lg"
                              :checked="viewSanctionPrevious"
                              @change="changeSanctionViewPrevious($event)"
                          ><small>View Previous Years</small></b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      <b-table
                          id="my-table"
                          :items="sanctionList"
                          :fields="tblSanctionFields"
                          class="mt-3"
                          hover
                          show-empty
                          sort-by="transaction_time"
                          sort-desc="Descending"
                          :bind="'sanctionListKey-' + sanctionListKey"
                      >
                        <template #empty="scope">
                          <h4>{{ scope.emptyText }}</h4>
                        </template>
                        <template #cell(guest_registration_url)="row">
                          <a :href="row.item.guest_registration_url" target="_blank">{{ row.item.guest_registration_url}}</a>
                        </template>
                        <template #cell(waiver)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button @click="downloadSanctionDocs(row.item.registration_id, 'sanctionWaiver')"
                                      class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                  <!-- /.card -->
                </div>
              </div>
              <div class="row profile-body " v-if="subdomain == 'waterski'">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3>President</h3>
                      </div>
                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px;margin-top:0;" @click="setContactType('2')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                        </b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row" v-if="clubuserProfileData.president_info!=null">
                        <div class="col-md-6 ">
                          <div class="form-group">
                            <label class="form-label">Member#</label>
                            <span>{{ clubuserProfileData.president_info.membership_id }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <span>{{ clubuserProfileData.president_info.email }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">First Name</label>
                            <span>{{ clubuserProfileData.president_info.first_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Last Name</label>
                            <span>{{ clubuserProfileData.president_info.last_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">City</label>
                            <span>{{ clubuserProfileData.president_info.city }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">State</label>
                            <span>{{ clubuserProfileData.president_info.state_code }}</span>
                          </div>
                        </div>

                        <div  class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Membership Status <span v-if="clubuserProfileData.president_info.member.status_text" v-b-tooltip.hover :title="clubuserProfileData.president_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                            <span class="text-success" v-if="clubuserProfileData.president_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.president_info.member.status_text }} (Expires {{ clubuserProfileData.president_info.member.valid_thru | formatDob }})</span>
                            <span class="text-warning"  v-else-if="clubuserProfileData.president_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubuserProfileData.president_info.member.status_text }}</span>
                            <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubuserProfileData.president_info.member.status_text }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="clubuserProfileData.president_info.member.member_type_id != 2">
                          <div class="form-group">
                            <label class="form-label">Waiver Status</label>
                            <span class="text-success" v-if="clubuserProfileData.president_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                            <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                          </div>
                        </div>
                        <template v-if="subdomain == 'waterski'">
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.president_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label" >Safe Sport Training Status</label>
                              <span class="text-success" v-if="clubuserProfileData.president_info.member.safe_sport != null && clubuserProfileData.president_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.president_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubuserProfileData.president_info.member.safe_sport.expiration }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.president_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.president_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubuserProfileData.president_info.member.safe_sport.safe_sport_status == 13 && clubuserProfileData.president_info.member.safe_sport.expiration"><br>(On {{ clubuserProfileData.president_info.member.safe_sport.expiration }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">Safe Sport Training Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.president_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">Background Screen Status <span v-if="clubuserProfileData.president_info.member.background_screening != null && clubuserProfileData.president_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.president_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.president_info.member.background_screening != null && clubuserProfileData.president_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.president_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.president_info.member.background_screening.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.president_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.president_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.president_info.member.background_screening.status == 6 && clubuserProfileData.president_info.member.background_screening.expiration"><br>(On {{ clubuserProfileData.president_info.member.background_screening.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">Background Screen Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.president_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">MVR Status <span v-if="clubuserProfileData.president_info.member.background_screening != null && clubuserProfileData.president_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.president_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.president_info.member.background_mvr_review != null && clubuserProfileData.president_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.president_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.president_info.member.background_mvr_review.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.president_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.president_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.president_info.member.background_mvr_review.status == 6 && clubuserProfileData.president_info.member.background_mvr_review.expiration"><br>(On {{ clubuserProfileData.president_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">MVR Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row" v-if="clubuserProfileData.president_info==null">
                        There are no records to show
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="subdomain != 'shooting'">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3>Vice President</h3>
                      </div>
                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px;margin-top:0;" @click="setContactType('3')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                        </b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row" v-if="clubuserProfileData.vp_info!=null">
                        <div class="col-md-6 ">
                          <div class="form-group">
                            <label class="form-label">Member#</label>
                            <span>{{ clubuserProfileData.vp_info.membership_id }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <span>{{ clubuserProfileData.vp_info.email }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">First Name</label>
                            <span>{{ clubuserProfileData.vp_info.first_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Last Name</label>
                            <span>{{ clubuserProfileData.vp_info.last_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">City</label>
                            <span>{{ clubuserProfileData.vp_info.city }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">State</label>
                            <span>{{ clubuserProfileData.vp_info.state_code }}</span>
                          </div>
                        </div>

                        <div  class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Membership Status <span v-if="clubuserProfileData.vp_info.member.status_text" v-b-tooltip.hover :title="clubuserProfileData.vp_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                            <span class="text-success" v-if="clubuserProfileData.vp_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.vp_info.member.status_text }} (Expires {{ clubuserProfileData.vp_info.member.valid_thru | formatDob }})</span>
                            <span class="text-warning"  v-else-if="clubuserProfileData.vp_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubuserProfileData.vp_info.member.status_text }}</span>
                            <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubuserProfileData.vp_info.member.status_text }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="clubuserProfileData.vp_info.member.member_type_id != 2">
                          <div class="form-group">
                            <label class="form-label">Waiver Status</label>
                            <span class="text-success" v-if="clubuserProfileData.vp_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                            <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                          </div>
                        </div>
                        <template v-if="subdomain == 'waterski'">
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.vp_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label" >Safe Sport Training Status</label>
                              <span class="text-success" v-if="clubuserProfileData.vp_info.member.safe_sport != null && clubuserProfileData.vp_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.vp_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubuserProfileData.vp_info.member.safe_sport.expiration }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.vp_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.vp_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubuserProfileData.vp_info.member.safe_sport.safe_sport_status == 13 && clubuserProfileData.vp_info.member.safe_sport.expiration"><br>(On {{ clubuserProfileData.vp_info.member.safe_sport.expiration }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">Safe Sport Training Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.vp_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">Background Screen Status <span v-if="clubuserProfileData.vp_info.member.background_screening != null && clubuserProfileData.vp_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.vp_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.vp_info.member.background_screening != null && clubuserProfileData.vp_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.vp_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.vp_info.member.background_screening.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.vp_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.vp_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.vp_info.member.background_screening.status == 6 && clubuserProfileData.vp_info.member.background_screening.expiration"><br>(On {{ clubuserProfileData.vp_info.member.background_screening.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">Background Screen Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.vp_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">MVR Status <span v-if="clubuserProfileData.vp_info.member.background_screening != null && clubuserProfileData.vp_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.vp_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.vp_info.member.background_mvr_review != null && clubuserProfileData.vp_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.vp_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.vp_info.member.background_mvr_review.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.vp_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.vp_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.vp_info.member.background_mvr_review.status == 6 && clubuserProfileData.vp_info.member.background_mvr_review.expiration"><br>(On {{ clubuserProfileData.vp_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">MVR Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row" v-if="clubuserProfileData.vp_info==null">
                        There are no records to show
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row profile-body" v-if="subdomain == 'waterski'">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3>Secretary</h3>
                      </div>
                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px; margin-top:0;" @click="setContactType('4')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                        </b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row" v-if="clubuserProfileData.secretary_info!=null">
                        <div class="col-md-6 ">
                          <div class="form-group">
                            <label class="form-label">Member#</label>
                            <span>{{ clubuserProfileData.secretary_info.membership_id }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <span>{{ clubuserProfileData.secretary_info.email }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">First Name</label>
                            <span>{{ clubuserProfileData.secretary_info.first_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Last Name</label>
                            <span>{{ clubuserProfileData.secretary_info.last_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">City</label>
                            <span>{{ clubuserProfileData.secretary_info.city }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">State</label>
                            <span>{{ clubuserProfileData.secretary_info.state_code }}</span>
                          </div>
                        </div>

                        <div  class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Membership Status <span v-if="clubuserProfileData.secretary_info.member.status_text" v-b-tooltip.hover :title="clubuserProfileData.secretary_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                            <span class="text-success" v-if="clubuserProfileData.secretary_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.secretary_info.member.status_text }} (Expires {{ clubuserProfileData.secretary_info.member.valid_thru | formatDob }})</span>
                            <span class="text-warning"  v-else-if="clubuserProfileData.secretary_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubuserProfileData.secretary_info.member.status_text }}</span>
                            <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubuserProfileData.secretary_info.member.status_text }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="clubuserProfileData.secretary_info.member.member_type_id != 2">
                          <div class="form-group">
                            <label class="form-label">Waiver Status</label>
                            <span class="text-success" v-if="clubuserProfileData.secretary_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                            <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                          </div>
                        </div>
                        <template v-if="subdomain == 'waterski'">
                          <div  class="col-md-6">
                            <div  class="col-md-6">
                              <div class="form-group" v-if="clubuserProfileData.secretary_info.member.safe_sport_screening_compliance == true">
                                <label class="form-label" >Safe Sport Training Status</label>
                                <span class="text-success" v-if="clubuserProfileData.secretary_info.member.safe_sport != null && clubuserProfileData.secretary_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.secretary_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubuserProfileData.secretary_info.member.safe_sport.expiration }})</span>
                                <span class="text-warning" v-else-if="clubuserProfileData.secretary_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.secretary_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubuserProfileData.secretary_info.member.safe_sport.safe_sport_status == 13 && clubuserProfileData.secretary_info.member.safe_sport.expiration"><br>(On {{ clubuserProfileData.secretary_info.member.safe_sport.expiration }})</template></span>
                                <span class="text-warning" v-else>Not Started</span>
                              </div>
                              <div class="form-group" v-else>
                                <label class="form-label">Safe Sport Training Status</label>
                                <span class="">N/A</span>
                              </div>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.secretary_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">Background Screen Status <span v-if="clubuserProfileData.secretary_info.member.background_screening != null && clubuserProfileData.secretary_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.secretary_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.secretary_info.member.background_screening != null && clubuserProfileData.secretary_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.secretary_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.secretary_info.member.background_screening.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.secretary_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.secretary_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.secretary_info.member.background_screening.status == 6 && clubuserProfileData.secretary_info.member.background_screening.expiration"><br>(On {{ clubuserProfileData.secretary_info.member.background_screening.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">Background Screen Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.secretary_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">MVR Status <span v-if="clubuserProfileData.secretary_info.member.background_screening != null && clubuserProfileData.secretary_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.secretary_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.secretary_info.member.background_mvr_review != null && clubuserProfileData.secretary_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.secretary_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.secretary_info.member.background_mvr_review.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.secretary_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.secretary_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.secretary_info.member.background_mvr_review.status == 6 && clubuserProfileData.secretary_info.member.background_mvr_review.expiration"><br>(On {{ clubuserProfileData.secretary_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">MVR Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row" v-if="clubuserProfileData.secretary_info==null">
                        There are no records to show
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="subdomain != 'shooting'">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3>Treasurer</h3>
                      </div>
                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px;margin-top:0;" @click="setContactType('5')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                        </b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row" v-if="clubuserProfileData.treasurer_info!=null">
                        <div class="col-md-6 ">
                          <div class="form-group">
                            <label class="form-label">Member#</label>
                            <span>{{ clubuserProfileData.treasurer_info.membership_id }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <span>{{ clubuserProfileData.treasurer_info.email }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>First Name</label>
                            <span>{{ clubuserProfileData.treasurer_info.first_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Last Name</label>
                            <span>{{ clubuserProfileData.treasurer_info.last_name }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">City</label>
                            <span>{{ clubuserProfileData.treasurer_info.city }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">State</label>
                            <span>{{ clubuserProfileData.treasurer_info.state_code }}</span>
                          </div>
                        </div>

                        <div  class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Membership Status <span v-if="clubuserProfileData.treasurer_info.member.status_text" v-b-tooltip.hover :title="clubuserProfileData.treasurer_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                            <span class="text-success" v-if="clubuserProfileData.treasurer_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.treasurer_info.member.status_text }} (Expires {{ clubuserProfileData.treasurer_info.member.valid_thru | formatDob }})</span>
                            <span class="text-warning"  v-else-if="clubuserProfileData.treasurer_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubuserProfileData.treasurer_info.member.status_text }}</span>
                            <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubuserProfileData.treasurer_info.member.status_text }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="clubuserProfileData.treasurer_info.member.member_type_id != 2">
                          <div class="form-group">
                            <label class="form-label">Waiver Status</label>
                            <span class="text-success" v-if="clubuserProfileData.treasurer_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                            <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                          </div>
                        </div>
                        <template v-if="subdomain == 'waterski'">
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.treasurer_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label" >Safe Sport Training Status</label>
                              <span class="text-success" v-if="clubuserProfileData.treasurer_info.member.safe_sport != null && clubuserProfileData.treasurer_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.treasurer_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubuserProfileData.treasurer_info.member.safe_sport.expiration }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.treasurer_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.treasurer_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubuserProfileData.treasurer_info.member.safe_sport.safe_sport_status == 13 && clubuserProfileData.treasurer_info.member.safe_sport.expiration"><br>(On {{ clubuserProfileData.treasurer_info.member.safe_sport.expiration }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">Safe Sport Training Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.treasurer_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">Background Screen Status <span v-if="clubuserProfileData.treasurer_info.member.background_screening != null && clubuserProfileData.treasurer_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.treasurer_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.treasurer_info.member.background_screening != null && clubuserProfileData.treasurer_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.treasurer_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.treasurer_info.member.background_screening.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.treasurer_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.treasurer_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.treasurer_info.member.background_screening.status == 6 && clubuserProfileData.treasurer_info.member.background_screening.expiration"><br>(On {{ clubuserProfileData.treasurer_info.member.background_screening.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">Background Screen Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                          <div  class="col-md-6">
                            <div class="form-group" v-if="clubuserProfileData.treasurer_info.member.safe_sport_screening_compliance == true">
                              <label class="form-label">MVR Status <span v-if="clubuserProfileData.treasurer_info.member.background_screening != null && clubuserProfileData.treasurer_info.member.background_screening.background_screening_info" v-b-tooltip.hover :title="clubuserProfileData.treasurer_info.member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="clubuserProfileData.treasurer_info.member.background_mvr_review != null && clubuserProfileData.treasurer_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubuserProfileData.treasurer_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubuserProfileData.treasurer_info.member.background_mvr_review.expiration | formatDob }})</span>
                              <span class="text-warning" v-else-if="clubuserProfileData.treasurer_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubuserProfileData.treasurer_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubuserProfileData.treasurer_info.member.background_mvr_review.status == 6 && clubuserProfileData.treasurer_info.member.background_mvr_review.expiration"><br>(On {{ clubuserProfileData.treasurer_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">MVR Status</label>
                              <span class="">N/A</span>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row" v-if="clubuserProfileData.treasurer_info==null">
                        There are no records to show
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row profile-body" v-if="subdomain == 'waterski'">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3>Safe Sport Coordinator</h3>
                      </div>
                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('8')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                        </b-button>
                      </div>
                    </div>

                    <!-- /.card-header -->
                    <div class="card-body table-responsive">
   <div class="pl-2 pr-2">
   <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
   <ul class="jdlinfo">
      <li>Be age 18 or over; and</li>
   </ul>
   <ul class="jdlinfo">
      <li>Be a current <strong>Active Member</strong> (Individual Active, Under 25 Active or Family Active, Ambassador Lifetime or Life Active) of USA Water Ski &amp; Wake Sports; and</li>
   </ul>
   <ul class="jdlinfo">
      <li>Maintain current <strong>Safe Sport Training</strong></li>
   </ul>
</div>
                      <b-table
                          id="my-table"
                          :items="clubuserProfileData.safe_sport_coordinator"
                          :fields="tblFields"
                          class="mt-3"
                          hover
                          show-empty
                      >
                        <template
                            :fields="items"
                        >
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                              <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                              </button>
                              <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #empty="scope">
                          <h6>{{ scope.emptyText }}</h6>
                        </template>
                        <template #cell(actions)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                                  class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #cell(memStatus)="row">
                          <span class="text-success" v-if="row.item.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }}) <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span class="text-warning" v-else-if="row.item.member.status >=7"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span class="text-danger" v-else><img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                        </template>
                        <template #cell(waiverStatus)="row">
                          <span class="text-success" v-if="row.item.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">Signed</span>
                          <span v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">Not signed</span>
                        </template>
                        <template #cell(ssStatus)="row">
                          <span class="text-success" v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                          <span class="text-warning" v-else-if="row.item.member.safe_sport_screening_compliance == true"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span class="text-danger" v-else>Not Started</span>
                        </template>
                        <template #cell(bgStatus)="row">
                          <span v-if="row.item.member.background_screening">
                            <span class="text-success" v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>N/A</span>
                        </template>
                        <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="text-success" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_mvr_review.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>Not Started</span>
                        </template>
                      </b-table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                  <!-- /.card -->
                </div>
              </div>

              <div class="row profile-body" v-if="subdomain == 'waterski'">
                <div class="col-md-12" v-if="subdomain == 'waterski'">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3>Show Director(s)</h3>
                      </div>
                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('6')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                        </b-button>
                      </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive">
                      <div class="pl-2 pr-2">
                      <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                      <ul class="jdlinfo">
                      <li>Be age 18 or over; and</li>
                      </ul>
                      <ul class="jdlinfo">
                      <li>Be a current <strong>Active member</strong> (Individual Active, Under 25 Active or Family Active, Ambassador Lifetime or Life Active) of USA Water Ski &amp; Wake Sports; and</li>
                      </ul>
                      <ul class="jdlinfo">
                      <li>Maintain current <strong>Safe Sport Training and Background Screening</strong></li>
                      </ul>
                      </div>
                      <b-table
                          id="my-table"
                          :items="clubuserProfileData.ski_directors"
                          :fields="tblFields"
                          class="mt-3"
                          hover
                          show-empty
                      >
                        <template
                            :fields="items"
                        >
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                              <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                              </button>
                              <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #empty="scope">
                          <h6>{{ scope.emptyText }}</h6>
                        </template>
                        <template #cell(actions)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                                  class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #cell(memStatus)="row">
                          <span class="text-success" v-if="row.item.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }}) <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span class="text-warning" v-else-if="row.item.member.status >=7"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span class="text-danger" v-else><img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                        </template>
                        <template #cell(waiverStatus)="row">
                          <span  class="text-success" v-if="row.item.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">Signed</span>
                          <span v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">Not signed</span>
                        </template>
                        <template #cell(ssStatus)="row">
                          <span class="text-success" v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                          <span class="text-warning" v-else-if="row.item.member.safe_sport_screening_compliance == true"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span class="text-danger" v-else>Not Started</span>
                        </template>
                        <template #cell(bgStatus)="row">
                          <span v-if="row.item.member.background_screening">
                            <span class="text-success" v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>N/A</span>
                        </template>
                        <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="text-success" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_mvr_review.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>Not Started</span>
                        </template>
                      </b-table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                  <!-- /.card -->
                </div>
              </div>

              <div class="row profile-body">

              </div>

              <div class="row profile-body" v-if="subdomain == 'waterski'">
                                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3>Junior Development Leader(s)</h3>
                      </div>

                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('1')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                        </b-button>
                      </div>

                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive">
                      <div class="pl-2 pr-2">
   <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
   <ul class="jdlinfo">
      <li>Be age 18 or over; and</li>
   </ul>
   <ul class="jdlinfo">
      <li>Be a current <b>Active member</b> (Individual Active, Under 25 Active or Family Active, Ambassador Lifetime or Life Active) of USA Water Ski &amp; Wake Sports; and</li>
   </ul>
   <ul class="jdlinfo">
      <li>Maintain current <b>Safe Sport Training and Background Screening</b></li>
   </ul>
</div>
                      <b-table
                          id="my-table"
                          :items="clubuserProfileData.junior_development"
                          :fields="tblFields"
                          class="mt-3"
                          hover
                          show-empty
                      >
                        <template
                            :fields="items"
                        >
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                              <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                              </button>
                              <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #empty="scope">
                          <h6>{{ scope.emptyText }}</h6>
                        </template>
                        <template #cell(actions)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                                  class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #cell(memStatus)="row">
                          <span class="text-success" v-if="row.item.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }}) <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span  class="text-warning" v-else-if="row.item.member.status >=7"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span class="text-danger" v-else><img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                        </template>
                        <template #cell(waiverStatus)="row">
                          <span class="text-success" v-if="row.item.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">Signed</span>
                          <span v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">Not signed</span>
                        </template>
                        <template #cell(ssStatus)="row">
                          <span class="text-success" v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                          <span class="text-warning" v-else-if="row.item.member.safe_sport_screening_compliance == true"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span class="text-danger" v-else>Not Started</span>
                        </template>
                        <template #cell(bgStatus)="row">
                          <span v-if="row.item.member.background_screening">
                            <span class="text-success" v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>N/A</span>
                        </template>
                        <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="text-success" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_mvr_review.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>Not Started</span>
                        </template>
                      </b-table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                  <!-- /.card -->
                </div>
                <div class="col-md-12">
                  <div class="card  border-none">
                    <div class="card-header bg-white">
                      <div class="header-title">
                        <h3>Assistant Show Director(s)</h3>
                      </div>

                      <div class="modal-btn">
                        <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('7')">
                          <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                        </b-button>
                      </div>
                    </div>

                    <!-- /.card-header -->
                    <div class="card-body table-responsive">
                                          <div class="pl-2 pr-2">
                      <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                      <ul class="jdlinfo">
                      <li>Be age 18 or over; and</li>
                      </ul>
                      <ul class="jdlinfo">
                      <li>Be a current <strong>Active member</strong> (Individual Active, Under 25 Active or Family Active, Ambassador Lifetime or Life Active) of USA Water Ski &amp; Wake Sports; and</li>
                      </ul>
                      <ul class="jdlinfo">
                      <li>Maintain current <strong>Safe Sport Training and Background Screening</strong></li>
                      </ul>
                    </div>
                      <b-table
                          id="my-table"
                          :items="clubuserProfileData.assistant_skidirectors"
                          :fields="tblFields"
                          class="mt-3"
                          hover
                          show-empty
                      >
                        <template
                            :fields="items"
                        >
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                              <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                              </button>
                              <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #empty="scope">
                          <h6>{{ scope.emptyText }}</h6>
                        </template>
                        <template #cell(actions)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                              <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                                  class="fas fa-trash"></i></button>
                            </div>
                          </div>
                        </template>
                        <template #cell(memStatus)="row">
                          <span class="text-success" v-if="row.item.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }}) <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span class="text-warning" v-else-if="row.item.member.status >=7"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                          <span class="text-danger"  v-else><img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png">{{ row.item.member.status_text }} <span v-if="row.item.member.status_text" v-b-tooltip.hover :title="row.item.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></span>
                        </template>
                        <template #cell(waiverStatus)="row">
                          <span class="text-success" v-if="row.item.member.waiver_status == 1">
                            <img class="mr-1" src="/dist/img/profile/ico-status-current.png">Signed </span>
                          <span class="text-danger" v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">Not signed</span>
                        </template>
                        <template #cell(ssStatus)="row">
                          <span class="text-success" v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                          <span class="text-warning" v-else-if="row.item.member.safe_sport_screening_compliance == true"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span class="text-danger" v-else>Not Started</span>
                        </template>
                        <template #cell(bgStatus)="row">
                          <span v-if="row.item.member.background_screening">
                            <span class="text-success" v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>N/A</span>
                        </template>
                        <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="text-success" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="text-danger" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_mvr_review.expiration | formatDob }})</template></span>
                          </span>
                          <span v-else>Not Started</span>
                        </template>
                      </b-table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                  <!-- /.card -->
                </div>
              </div>

                <div class="row profile-body mb-3"  v-if="subdomain == 'alliancefastpitch'">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Team Roster</h3>
                                </div>
                              <div class="header-actions pt-2">
                                <div class="form-check-inline">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" v-model="excludeExpiredRoster" @change="excludeRosterExpired"> Active Members Only
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="card-body pt-0 table-responsive">
                                <b-table
                                    id="my-table"
                                    :items="rostersInfo"
                                    :fields="tblrosterFields"
                                    :busy="!loaded"
                                    class="mt-3"
                                    hover
                                    show-empty
                                >
                                    <template
                                        :fields="items"
                                    >
                                        <div class="text-left py-0 align-middle">
                                            <div class="btn-group btn-group-sm">
                                                <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                                <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </template>
                                    <template #empty="scope">
                                        <h4>{{ scope.emptyText }}</h4>
                                    </template>
                                     
                                    <template #cell(first_name)="row">
                                        {{row.item.personal_details.first_name}}
                                    </template>
                                    <template #cell(last_name)="row">
                                        {{row.item.personal_details.last_name}}
                                    </template>
                                    <template #cell(membership_name)="row">
                                        {{row.item.latest_registration.membership.name}}
                                    </template>
                                    <template #cell(membership_endtate)="row">
                                        {{formatDate(row.item.latest_registration.valid_thru)}}
                                    </template>
                                    <template #cell(member_status)="row">
                                        {{row.item.status_text}}
                                    </template>
                                  <template #cell(roster_status)="row">
                                    {{rosterStatus[row.item.roster_status]}}
                                  </template>
                                  <template #cell(actions)="row">
                                    <div class="text-left py-0 align-middle">
                                      <div class="btn-group btn-group-sm">
                                        <button v-if="subdomain == 'alliancefastpitch' && isTransferEnable == 1 && ( clubuserProfileData.registration.reg_status != 'Expired' && row.item.status_text != 'Expired')" class="btn btn-danger ml-1" @click="transferRoster(row.item.id,row.item.personal_details.dob)"
                                        ><i
                                            class="fas fa-share"></i></button>
                                      </div>
                                    </div>
                                  </template>
                                  <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                      <b-spinner class="align-middle"></b-spinner>
                                      <strong>Loading...</strong>
                                    </div>
                                  </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="subdomain === 'alliancefastpitch'">
                  <StaffList
                      :list-data="staffInfo"
                      :clubuser-profile-data="clubuserProfileData"
                      @refreshProfile="userProfileApi"
                  />
                </template>
                <div class="row profile-body mb-3">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Membership History</h3>
                                </div>
                            </div>
                            <div class="card-body pt-0 table-responsive">
                                <b-table
                                    id="my-table"
                                    :items="clubuserProfileData.membership_history"
                                    :fields="membershipFields"
                                    class="mt-3"
                                    hover
                                    show-empty
                                >
                                    <template
                                        :fields="items"
                                    >
                                        <div class="text-left py-0 align-middle">
                                            <div class="btn-group btn-group-sm">
                                                <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                                <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </template>
                                    <template #empty="scope">
                                        <h4>{{ scope.emptyText }}</h4>
                                    </template>
                                    <template #cell(waiver)="row">
                                        <div class="text-left py-0 align-middle">
                                            <div class="btn-group btn-group-sm">
                                                <button @click="downloadDocs(row.item.id, 'waiver')"
                                                        class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(status)="row">
                                        {{row.item.transaction_status}}
                                    </template>

                                    <template #cell(receipt)="row">
                                        <div class="text-left py-0 align-middle">
                                            <div class="btn-group btn-group-sm">
                                                <button @click="downloadDocs(row.item.id, 'payment')"
                                                        class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                                            </div>
                                        </div>
                                    </template>
                                </b-table>


                            </div>
                        </div>
                    </div>
                </div>


               <club-roasters-component v-if="!isAllianceFastPitch" ref="ClubRoastersComponent" :regId="$route.params.regId"></club-roasters-component>


                <div class="row profile-body" v-if="clubuserProfileData.registration.donation_amount" v-show="clubuserProfileData.registration.donation_amount>0">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Donation</h3>
                                </div>
                            </div>
                            <div class="card-body pt-0 table-responsive">
                                <b-table
                                    id="myDonationTable"
                                    :items="clubuserProfileData.donations"
                                    :fields="donationTbl"
                                    class="mt-3"
                                    hover
                                    show-empty
                                >
                                    <template #cell(sports_discipline)="row" v-if="subdomain=='alliancefastpitch' ">
                                        {{ row.item.type }}
                                    </template>                                    
                                    <template #cell(sports_discipline)="row" v-else>
                                      <b-button variant="outline-primary" size="sm"
                                                @click="dispDisciplineModal(row.item, row.index, $event.target)">
                                        <i class="fas fa-pencil-alt"></i>
                                      </b-button>
                                        {{ listOfDonationSportDiscipline[row.value] }}
                                    </template>
                                    <template #cell(active)>
                                        Paid
                                    </template>
                                    <template #cell(receipt)="row">
                                      <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                          <button @click="downloadDonarionDocs(row.item.id)"
                                                  class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                                        </div>
                                      </div>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Purchase History -->
                <div class="row profile-body" id="purchaseHistorySection" v-if="this.getSiteProps('general.purchaseHistory')">
                  <div class="col-md-12">
                    <div class="card mt-3">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Purchase History</h3>
                            </div>
                        </div>
                        
                        <div class="card-body mt-4 pt-0">
                            <div class="d-flex">
                            <div class="col-md-3">
                                <b-form inline>
                                    <label class="mr-sm-2 font-weight-normal">Show</label>
                                    <b-form-select
                                    id="inline-form-custom-select-pref"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    v-model="purchaseHistory.perPage"
                                    :options="purchaseHistory.viewEntryOptions"
                                    size="md"
                                    ></b-form-select
                                    >entries
                                </b-form>
                                </div>
                                <div class="col-md-6 d-inline-flex">
                                    <div class="form-inline mr-1">
                                        <label class="form-label mr-2">From Date</label>
                                        <div class="form-group birthdate">
                                            <div class="birthdaypicker">
                                                <date-pick
                                                    v-model="purchaseHistory.filter.startDate"
                                                    :format="'MM/DD/YYYY'"
                                                    :displayFormat="'MM/DD/YYYY'"
                                                    placeholder="start"
                                                    v-mask="'##/##/####'"
                                                    :parseDate="parseDatePick"
                                                ></date-pick>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-inline">
                                        <label class="form-label mr-2">To Date</label>
                                        <div class="form-group birthdate">
                                            <div class="birthdaypicker">
                                                <date-pick
                                                    v-model="purchaseHistory.filter.endDate"
                                                    :format="'MM/DD/YYYY'"
                                                    :displayFormat="'MM/DD/YYYY'"
                                                    placeholder="End"
                                                    v-mask="'##/##/####'"
                                                    :parseDate="parseDatePick"
                                                ></date-pick>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                <b-input-group size="md">
                                    <b-form-input
                                    class="border border-secondary"
                                    id="filter-input"
                                    v-model="purchaseHistory.filter.search"
                                    type="search"
                                    placeholder="Search"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                        <b-table
                            id="purchaseHistoryTable"
                            :per-page="purchaseHistory.perPage"
                            :current-page="purchaseHistory.currentPage"
                            :items="purchaseHistory.data"
                            :fields="purchaseHistoryTbl"
                            class="mt-3"
                            :busy="!purchaseHistory.loaded"
                            :filter="purchaseHistory.filter"
                            :filter-included-fields="purchaseHistory.filterOn"
                            :filter-function="customFilter"
                            responsive='true'
                            @filtered="onFilteredPurchaseHistory"
                            hover
                            show-empty
                        >
                          <template #cell(receipt)="row">
                            <div class="text-left py-0 align-middle">
                                <div class="btn-group btn-group-sm" v-if="row.item.transaction_type == 'donation'">
                                    <button @click="downloadDonarionDocs(row.item.donation.id)"
                                            class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                                </div>
                                <div class="btn-group btn-group-sm" v-else-if="row.item.transaction_type == 'registraion' && !row.item.name.includes('Discount Coupon')">
                                    <button @click="downloadDocs(row.item.ref_regid, 'payment')"
                                            class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                                </div>
                              <div class="btn-group btn-group-sm" v-else-if="['insurance','sanction'].includes(row.item.transaction_type)">
                                <button @click="downloadOtherDocs(row.item.ref_regid, row.item.transaction_type, row.item.transaction_id)"
                                        class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                              </div>
                            </div>
                        </template>
                        </b-table>
                        <br />
                        <b-pagination
                            v-if="purchaseHistory.loaded"
                            v-model="purchaseHistory.currentPage"
                            :total-rows="purchaseHistoryRows"
                            :per-page="purchaseHistory.perPage"
                            prev-text="Previous"
                            next-text="Next"
                            aria-controls="purchaseHistoryTable"
                            class="float-right custom-color"
                            hide-goto-end-buttons
                        ></b-pagination>
                        </div>
                    </div>
                  </div>
                </div>
                 <div class="row profile-body" id="noteList">
                  <div class="col-md-12">
                    <div class="card table-head Merchandise mb-4">
                      <div class="card-header">
                        <div class="header-title" style="width: 100%">
                          <div class="row">
                            <div class="col-md-11">
                              <h3>Notes</h3>
                            </div>
                            <div class="col-md-1 text-right pt-1">
                              <button class="btn btn-primary btn-blue btn-sm" v-b-modal.note-modal @click="addnote()"><i class="fa fa-plus"></i> Add</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <section id="notes_section">
                      <div class="card-body pt-0 table-responsive">
                        <b-table
                           id="myMerchandiseTable"
                           :items="userNote.notes"
                           :fields="notesTbl"
                           class="mt-3"
                           hover
                           show-empty
                        >
                         <template #cell(note)="row">
                          {{row.item.note}}
                         </template>
                         <template #cell(name)="row">
                          {{row.item.user.first_name}}
                         </template>
                         <template #cell(status)="row">
                            {{row.item.status!='0' ? 'Active' : 'In Active'}}
                         </template>
                         <template #cell(actions)="row">
                           <div class="text-left py-0 align-middle">
                             <div class="btn-group btn-group-sm">
                               <button
                                  data-bs-toggle="modal"  v-b-modal.note-modal
                                   class="btn btn-primary ml-1"
                                   @click="editNote(row.item, $event.target)"
                               >
                                 <i class="fas fa-pencil-alt"></i>
                               </button>
                               <button
                                   class="btn btn-danger ml-1"
                                   @click="deleteNote(row.item.id)"
                               >
                                 <i class="fas fa-trash"></i>
                               </button>
                             </div>
                           </div>
                         </template>
                       </b-table>
                      </div>
                      </section>
                   </div>
                 </div>
               </div>

              <b-modal id="modal-center1" size="lg" ok-title="Update" @ok="updateClubInfo" centered :title="prop_fields.club_info.tab_title || 'Club Information'">
                <div class="form-body">
                  <form>
                    <div class="row">
                      <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.clubname">
                        <div class="form-group">
                          <label class="form-label" for="clubname">{{prop_fields.club_info.clubname.label || 'Club Name'}}<sup class="text-danger">*</sup></label>
                          <input type="text" id="clubname" v-model="UpdateCLub.name" :placeholder="prop_fields.club_info.clubname.placeholder||'Castro Valley Ski Club'" class="form-control" />
                          <span class="text-sm text-danger">{{ errors.name }}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.include_directory">
                        <label class="form-label" for=""></label>
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="directoryYes" :value="1" v-model="UpdateCLub.exclude" name="form.exclude">
                          <label class="form-check-label" for="directoryYes">Include in USA-WSWS online club directory</label>
                        </div>
                      </div>
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Start Date</label>
                          <date-pick
                              v-model="UpdateCLub.start_date"
                              :pickTime="false"
                              name="UpdateProfile.start_date"
                              id="startDate"
                              :format="'MM/DD/YYYY'"
                              :selectableYearRange="{from: 1900, to: 2021}"
                              v-mask="'##/##/####'"
                              placeholder="MM/DD/YYYY"
                              class="form-control"
                              :parseDate="parseDatePick"
                          ></date-pick>
                        </div>
                      </div>
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Expiration Date</label>
                          <date-pick
                              v-model="UpdateCLub.valid_thru"
                              :pickTime="false"
                              name="UpdateProfile.valid_thru"
                              id="validThru"
                              :format="'MM/DD/YYYY'"
                              :selectableYearRange="{from: 1900, to: 2021}"
                              v-mask="'##/##/####'"
                              placeholder="MM/DD/YYYY"
                              class="form-control"
                              :parseDate="parseDatePick"
                          ></date-pick>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.primary_club_type">
                        <div class="form-group">
                          <label class="form-label" for="clubtype">Primary Club Type <sup class="text-danger">*</sup></label>
                          <b-form-select v-model="UpdateCLub.type" :options="clubType" plain></b-form-select>
                          <span class="text-sm text-danger">{{ errors.type }}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.secondary_club_type">
                        <div class="form-group">
                          <label class="form-label" for="clubtype">Secondary Club Type</label>
                          <select
                              v-model="UpdateCLub.secondary_type"
                              id="secondary_type"
                              class="form-control"
                          >
                            <option
                                v-for="(typ, val) in clubType"
                                :value="val"
                                :key="val"
                            >{{ typ }}</option
                            >
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_website">
                        <div class="form-group">
                          <label class="form-label" for="clubwebsite">Club Website</label>
                          <input
                              type="text"
                              id="clubwebsite"
                              v-model="UpdateCLub.website"
                              placeholder="clubnew.com"
                              class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.email">
                        <div class="form-group">
                          <label for="email" class="form-label">Club E-Mail <sup class="text-danger">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="email" v-model="UpdateCLub.email" name="form.email">
                          <span class="text-sm text-danger">{{ errors.email}}</span>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_address">
                        <div class="form-group">
                          <label for="club_address" class="form-label">Club Mailing Address <sup class="text-danger" v-if="prop_fields.club_info.club_address.required">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="club_address" name="form.address" v-model="UpdateCLub.address">
                          <span class="text-sm text-danger">{{ errors.club_info_address }}</span>
                        </div>
                      </div>
                        <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_address2">
                          <div class="form-group">
                            <label for="club_address" class="form-label">Address 2</label>
                            <input type="text" class="form-control" maxlength="45" id="club_address" name="form.address2" v-model="UpdateCLub.address2">
                          </div>
                        </div>
                        <!-- <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.include_directory">
                          <div class="form-group">
                            <label for="club_country" class="form-label">Country</label>
                            <input type="text" class="form-control" maxlength="45" id="club_country" name="form.country" v-model="UpdateCLub.country">
                          </div>
                        </div> -->

                        <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_city">
                          <div class="form-group">
                            <label for="club_city" class="form-label">City <sup class="text-danger" v-if="prop_fields.club_info.club_city.required">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" id="club_city" name="form.city" v-model="UpdateCLub.city">
                            <span class="text-sm text-danger">{{ errors.club_info_city }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_state_code">
                          <div class="form-group">
                            <label for="club_state" class="form-label">State <sup class="text-danger" v-if="prop_fields.club_info.club_state_code.required">*</sup></label>
                            <select class="form-control" id="club_state" name="form.state_code" v-model="UpdateCLub.state_code">
                              <option value="">Select</option>
                              <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.club_info_state_code }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="prop_fields.club_info && prop_fields.club_info.club_zip">
                          <div class="form-group">
                            <label for="club_zip" class="form-label">Zip/Postal Code <sup class="text-danger" v-if="prop_fields.club_info.club_zip.required">*</sup></label>
                            <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="club_zip"  name="form.zip" v-model="UpdateCLub.zip">
                            <span class="text-sm text-danger">{{ errors.club_info_zip }}</span>
                          </div>
                        </div>
                      <!--- Shooting --->
                      <div class="col-md-6" v-if="prop_fields.club_info.club_visible_type">
                          <div class="form-group">
                              <label for="club_visible_type" class="control-label">{{prop_fields.club_info.club_visible_type.label || 'Club Type'}}<sup class="text-danger" v-if="prop_fields.club_info.club_visible_type.required">*</sup></label>
                              <select name="UpdateCLub.club_visible_type" id="club_visible_type" class="form-control" v-model="UpdateCLub.visibility_type">
                                  <option value="">Select</option>
                                  <option v-for="(item, k) in prop_fields.club_info.club_visible_type.choices" :key="'club_visible_type'+k">{{ item }}</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.club_visible_type }}</span>
                          </div>
                      </div>
                      <div class="col-md-6" v-if="prop_fields.club_info.club_type">
                          <div class="form-group">
                              <label for="club_type" class="control-label">{{prop_fields.club_info.club_type.label || 'Club Type'}}<sup class="text-danger" v-if="prop_fields.club_info.club_type.required">*</sup></label>
                              <Select2 v-if="prop_fields.club_info.club_type.multiple" v-model="UpdateCLub.club_type" name="form.club_type" id="club_type" :options="prop_fields.club_info.club_type.choices" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                              <select name="form.club_type" id="club_type" class="form-control" v-model="UpdateCLub.club_type" v-else>
                                  <option value="">Select</option>
                                  <option v-for="(item, k) in prop_fields.club_info.club_type.choices" :key="'club_type'+k">{{ item }}</option>
                              </select>
                              <span class="text-sm text-danger">{{ errors.club_type }}</span>
                          </div>
                      </div>
                      <div class="col-md-12" v-if="prop_fields.club_info.club_discipilines">
                          <div class="form-group discipilineitem">
                              <label for="" class="form-label">{{ prop_fields.club_info.club_discipilines.label || 'Club Disciplines' }}<sup class="text-danger" v-if="prop_fields.club_info.club_discipilines">*</sup></label>
                              <div class="row mb-2">
                                  <div class="col-md-12">
                                      <div class="form-check">
                                          <input class="form-check-input club_discipilines_check" type="checkbox" id="clubdisciplines-all"
                                          name="club_disciplines_all" @change="triggerSelectDisciplinesAll">
                                          <label class="d-flex mr-1" for="clubdisciplines-all">Select all that apply</label>
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-md-4" v-for="(name, id) in clubDisciplinesList" :key="'cd_'+id">
                                      <div class="form-check">
                                          <input class="form-check-input club_discipilines_check" type="checkbox" :id="'clubdisciplines-'+id" name="form.club_disciplines" :value="id" v-model="UpdateCLub.club_disciplines">
                                          <label class="d-flex mr-1" :for="'clubdisciplines-'+id">{{ name }}</label>
                                      </div>
                                  </div>
                              </div>
                              <span class="text-sm text-danger">{{ errors.club_discipilines }}</span>
                          </div>
                      </div>
                      <div class="col-md-12" v-if="prop_fields.club_info.club_admin_member_id">
                          <div class="form-group">
                              <label for="clubname" class="form-label">{{ prop_fields.club_info.club_admin_member_id.label }}  <sup class="text-danger" v-if="prop_fields.club_info.club_admin_member_id.required">*</sup></label>
                              <div class="input-group">
                                  <input type="number" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_member_id" name="form.club_admin_member_id" v-model="UpdateCLub.club_admin_member_id">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-primary" type="button" @click="searchMember"><i class="fa fa-search"></i> Search</button>
                                  </div>
                              </div>
                              <span class="text-sm text-danger">{{ errors.club_admin_member_id }}</span>
                          </div>
                          <label class="text-danger" v-if="clubAdminSearch.searched && UpdateCLub.club_admin_name == ''">No Member Found with Member ID #{{ clubAdminSearch.memberIDSearched }}</label>
                      </div>
                      <div class="col-md-12" v-if="prop_fields.club_info.club_admin_name">
                          <div class="form-group">
                              <label for="clubname" class="form-label">{{ prop_fields.club_info.club_admin_name.label }}  <sup class="text-danger" v-if="prop_fields.club_info.club_admin_name.required">*</sup></label>
                              <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_name" name="form.club_admin_name" v-model="UpdateCLub.club_admin_name" :readonly="prop_fields.club_info.club_admin_name.readonly" :placeholder="prop_fields.club_info.club_admin_name.placeholder">
                              <span class="text-sm text-danger">{{ errors.club_admin_name }}</span>
                          </div>
                      </div>
                      <!--- Shooting --->
                      <!-- Alliance fastpitch -->
                      <div :class="'col-md-6'" v-if="prop_fields.club_info.age_division">
                        <div class="form-group">
                          <label for="age_division" class="form-label">{{ prop_fields.club_info.age_division.label }} <sup class="text-danger" v-if="prop_fields.club_info.age_division.required">*</sup></label>
                          <select class="form-control" id="age_division" v-model="UpdateCLub.age_division" name="UpdateCLub.age_division" >
                            <option value="">Select</option>
                            <option  v-for="(listOfAgeDivision,key) in ageDivisionOPt" :key="key"  :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">{{ listOfAgeDivision.text }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.age_division}}</span>
                        </div>
                      </div>
                      <div :class="'col-md-6'" v-if="prop_fields.club_info.member_league">
                        <div class="form-group">
                          <label for="member_league" class="form-label">{{ prop_fields.club_info.member_league.label }} <sup class="text-danger" v-if="prop_fields.club_info.member_league.required">*</sup></label>
<!--                          <input type="text" class="form-control" maxlength="100" id="member_league" v-model="clubuserProfileData.memberLeageue.name" v-bind:readonly="true" name="UpdateCLub.member_league">-->
                          <select class="form-control" id="member_league" v-model="UpdateCLub.member_league" name="UpdateCLub.member_league">
                            <option value="">Select</option>
                            <option  v-for="(leagueData,key) in teamLeague" :key="key" :value="leagueData.id">{{ leagueData.name }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.member_league }}</span>
                        </div>
                      </div>
                      <div :class="'col-md-6'" v-if="prop_fields.club_info.agl_team">
                        <div class="form-group">
                          <label for="agl_team_id" class="form-label">{{ prop_fields.club_info.agl_team.label }} <sup class="text-danger" v-if="prop_fields.club_info.agl_team.required">*</sup></label>
                          <input type="text" class="form-control" maxlength="100" id="agl_team_id" v-model="UpdateCLub.agl_team_id" name="UpdateCLub.agl_team">
                          <span class="text-sm text-danger">{{ errors.agl_team_id}}</span>
                        </div>
                      </div>
                      <!-- End -->
                    </div>
                  </form>
                </div>
              </b-modal>
               <b-modal id="note-modal" hide-footer  :no-close-on-backdrop="true">
         <template #modal-title>
		<h3 v-if="!edit_note_id">Add Note</h3> 
    <h3  v-else>Edit Note</h3> 
    </template>
    
		<div class="form-group">
        <label for="note">Note:</label>
			<textarea type="text" id="note" v-model="note" size="lg" placeholder="Enter Note" class="form-control" ></textarea>
      <p class="text-danger note-error"></p>
   
        </div>
    
<div class="form-group">
   <label for="notetype">Note Type:  </label>
      <select name="notetypeSelect" id="notetype" v-model="selectedNote"  class="form-control">
        <option disabled value=""> Select </option>
       <option v-for="(Notetypes,i) in Notetype.options" :key="i" :value=i>{{ Notetypes }}
                        </option>
      
    </select>
    <p class="text-danger note-type-error"></p>
</div>
<!-- <div class="form-group">
  
    <b-form-checkbox  v-model="notestatus" name="notestatus" switch value="1">
     Status
    </b-form-checkbox>
 
</div> -->
    <div>
        <button v-if="!edit_note_id"  type="button" class="btn btn-sm btn-primary"   @click="storenote()" :disabled="noteProgress"><i class="fa mr-2" :class="noteProgress ? 'fa-circle-notch' : 'fa-save'"></i> Submit</button>
        <button v-else  type="button" class="btn btn-sm btn-primary" @click="updatenote()" :disabled="noteProgress"><i class="fa mr-2" :class="noteProgress ? 'fa-circle-notch' : 'fa-save'"></i> Update</button>
       &nbsp;
        <!-- <button  type="button" class="btn btn-sm btn-secondary"  @click="$bvModal.hide('note-modal')">Close</button> -->
    </div>
      </b-modal>

           
              <member-lookup v-on:member-lookup-data="ChangeClubMember" :editContactType="editContactType"></member-lookup>
              <b-modal
                  id="updateClubInsurance"
                  title="Certificate(s) of Insurance Information"
                  size="lg"
                  @ok="handleOk($event, updInsurance.id)"
                  cancel-title="Fulfilled"
                  cancel-variant="primary"
                  @cancel="updateFulfilled(updInsurance.id, 1)"
                  :cancel-disabled="(insuranceMode!==1)"
                  :ok-disabled="(insuranceMode===1)"
              >
                <div class="popupbody">
                  <p class="font-13 mb-1">Complete for each third party requiring a certificate of insurance from your club with respect to USA Water Ski & Wake Sports sanctioned events. Each request is $50.</p>
                  <p class="font-13 text-danger"><i>Note: Coverage only applies with respect to tournaments, practices, exhibitions, clinics, and related activities sanctioned and approved by USA Water Ski & Wake Sports.</i> </p>
                  <div class="newname-inner">
                    <form action="">
                    <div class="row">
                      <div class="col-md-6" v-if="!notACompany">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="companyName" class="form-label">Company Name <sup class="text-danger">*</sup></label>
                              <input id="companyName" type="text" maxlength="250" class="form-control" name="updInsurance.name"
                                     value="" v-model="updInsurance.company_name" :readonly="insuranceMode===1">
                              <span class="text-sm text-danger">{{ errors.company_name }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" v-if="notACompany">
                        <div class="form-group">
                          <label for="firstName" class="form-label">First Name <sup class="text-danger">*</sup></label>
                          <input id="firstName" type="text" class="form-control" maxlength="90" name="updInsurance.first_name"
                                 value="" v-model="updInsurance.first_name" :readonly="insuranceMode===1">
                          <span class="text-sm text-danger">{{ errors.first_name }}</span>
                        </div>

                      </div>
                      <div class="col-md-3" v-if="notACompany">
                        <div class="form-group">
                          <label for="lastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                          <input id="lastName" type="text" class="form-control" maxlength="90" name="updInsurance.last_name"
                                 value=""  v-model="updInsurance.last_name" :readonly="insuranceMode===1">
                          <span class="text-sm text-danger">{{ errors.last_name }}</span>
                        </div>
                      </div>
                      <div class="col-md-3 mt-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="notACompany"
                                 name="notACompany" v-model="notACompany" value="1">
                          <label class="d-flex mr-1" for="notACompany">Not a company</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="address1" class="form-label">Address 1<sup class="text-danger">*</sup> </label>
                          <input id="address1" type="text" class="form-control" maxlength="45" name="updInsurance.address1"
                                 value="" v-model="updInsurance.address1" :readonly="insuranceMode===1">
                          <span class="text-sm text-danger">{{ errors.address1 }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="address2" class="form-label">Address 2 </label>
                          <input id="address2" type="text" class="form-control" maxlength="45" name="updInsurance.address2"
                                 value="" v-model="updInsurance.address2" :readonly="insuranceMode===1">
                          <span class="text-sm text-danger">{{ errors.address2 }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="city" class="form-label">City<sup class="text-danger">*</sup></label>
                          <input id="city" type="text" class="form-control" maxlength="45" name="updInsurance.city"
                                 value="" v-model="updInsurance.city" :readonly="insuranceMode===1">
                          <span class="text-sm text-danger">{{ errors.city }}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="state" class="form-label">State <sup class="text-danger">*</sup> </label>
                          <select class="form-control" name="updInsurance.state_code" v-model="updInsurance.state_code" :disabled="insuranceMode===1">
                            <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.state_code }}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="zipCode" class="form-label">Zip Code <sup class="text-danger">*</sup> </label>
                          <input id="zipCode" type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" name="updInsurance.zip"
                                 v-model="updInsurance.zip" :readonly="insuranceMode===1">
                          <span class="text-sm text-danger">{{ errors.zip }}</span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="homePhone" class="form-label">Phone  <sup class="text-danger">*</sup> </label>
                          <vue-tel-input :inputOptions="options" v-model="updInsurance.home_phone" ref="phone"
                                         :readonly="insuranceMode===1" maxlength="20" v-bind="homeProps"
                                         :validCharactersOnly="true"></vue-tel-input>
                          <span class="text-sm text-danger">{{ errors.home_phone }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="certificateFor" class="form-label">This certificate is for:<sup class="text-danger">*</sup> </label>
                          <div class="form-group ">
                            <label class="form-label" for="yearRoundclubactivity">
                              <input type="radio" id="yearRoundclubactivity"  name="updInsurance.certificate_type"
                                     v-model="updInsurance.certificate_type" value="year" :disabled="insuranceMode===1"> Year - Round Club Activity</label>
                            <label class="form-label" for="specificEvent">
                              <input type="radio" id="specificEvent" maxlength="20"  name="updInsurance.certificate_type"
                                     v-model="updInsurance.certificate_type" value="specificEvent" :disabled="insuranceMode===1"> Specific Event</label><br>
                            <span class="text-sm text-danger">{{ errors.certificate_type }}</span>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-12">
                        <div class="form-group certificatefor" v-if="updInsurance.certificate_type == 'specificEvent'">
                          <span class="font-13 text-danger"><i>Note: Certificate for Specific sanctioned events will not be issued until the event is sanctioned and approved by USA Water Ski & Wake Sports.</i></span><br>
                          <label for="eventType" class="form-label">Specific Event Title<sup class="text-danger">*</sup> </label>
                          <input type="text"  class="form-control" maxlength="45" name="updInsurance.specific_event_title"
                                 v-model="updInsurance.specific_event_title" :readonly="insuranceMode===1">
                          <span class="text-sm text-danger">{{ errors.specific_event_title }}</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group specificeventtype" v-if="updInsurance.certificate_type == 'specificEvent'">
                          <label for="eventType" class="form-label">Specific Sanctioned Event Type <sup class="text-danger">*</sup></label>
                          <div class="form-group row">
                            <label class="form-label" for="tournament">
                              <input type="radio" id="tournament" name="updInsurance.sanctioned_type"
                                     v-model="updInsurance.sanctioned_type" value="tournament" :disabled="insuranceMode===1"> Tournament</label>
                            <label class="form-label" for="practice">
                              <input type="radio" id="practice" name="updInsurance.sanctioned_type"
                                     v-model="updInsurance.sanctioned_type" value="practice" :disabled="insuranceMode===1"> Practice</label><br>
                            <label class="form-label" for="basicskillclinic">
                              <input type="radio" id="basicskillclinic" name="updInsurance.sanctioned_type"
                                     v-model="updInsurance.sanctioned_type" value="basicskillclinic" :disabled="insuranceMode===1"> Basic Skills Clinic</label>
                            <label class="form-label" for="exhibition">
                              <input type="radio" id="exhibition" name="updInsurance.sanctioned_type"
                                     v-model="updInsurance.sanctioned_type" value="exhibition" :disabled="insuranceMode===1"> Exhibition</label><br>
                            <div v-show="updInsurance.sanctioned_type == 'tournament'">
                              <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                            </div>
                            <div v-show="updInsurance.sanctioned_type == 'practice'">
                              <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                            </div>
                            <div v-show="updInsurance.sanctioned_type == 'basicskillclinic'">
                              <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                            </div>
                            <div v-show="updInsurance.sanctioned_type == 'exhibition'">
                              <p class="font-13 mb-1">Note: Certificates for specific sanctioned events will not be issued until the event is sanctioned and approved by USA-WSWS.</p>
                            </div>
                            <span class="text-sm text-danger">{{ errors.sanctioned_type }}</span>
                          </div>
                          <div class="row" v-if="updInsurance.certificate_type == 'specificEvent'">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="eventType" class="form-label">Event Sanction#  <sup class="text-danger">*</sup></label>
                                <input id="eventSanction" type="text" name="updInsurance.event_sanction" maxlength="45"
                                       v-model="updInsurance.event_sanction" class="form-control" :readonly="insuranceMode===1">
                                <span class="text-sm text-danger">{{ errors.event_sanction }}</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group insurence-datepicker" v-if="insuranceMode!==1">
                                  <label for="eventType" class="form-label">Specific Events Start Date </label><br>

                                  <date-pick
                                      class="form-control"
                                      v-model="updInsurance.start_date"
                                      :pickTime="false"
                                      name="updInsurance.start_date"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                  <span class="text-sm text-danger">{{ errors.start_date }}</span>
                                </div>
                                <div class="form-group insurence-datepicker" v-else>
                                  <label for="eventType" class="form-label">Specific Events Start Date </label><br>

                                  <input
                                      class="form-control"
                                      v-model="updInsurance.start_date"
                                      :pickTime="false"
                                      name="updInsurance.start_date"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      readonly
                                  >
                                  <span class="text-sm text-danger">{{ errors.start_date }}</span>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group insurence-datepicker" v-if="insuranceMode!==1">
                                  <label for="eventType" class="form-label">Specific Events End Date </label><br>

                                  <date-pick
                                      class="form-control"
                                      v-model="updInsurance.end_date"
                                      :pickTime="false"
                                      name="updInsurance.end_date"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      :disabled="insuranceMode===1"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                  <span class="text-sm text-danger">{{ errors.end_date }}</span>
                                </div>
                                <div class="form-group insurence-datepicker" v-else>
                                  <label for="eventType" class="form-label">Specific Events End Date </label><br>

                                  <input
                                      class="form-control"
                                      v-model="updInsurance.end_date"
                                      :pickTime="false"
                                      name="updInsurance.end_date"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      readonly
                                  >
                                  <span class="text-sm text-danger">{{ errors.end_date }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group ">
                          <label class="form-label">Has the club entered into any agreement, contract, or permit which contains assumption of liability, indemnification, or hold harmless language? <sup class="text-danger">*</sup></label>
                          <div class="">
                            <label class="form-label" for="agreeyes">
                              <input type="radio" id="agreeyes" name="updInsurance.team_has_any_agreement"
                                     v-model="updInsurance.team_has_any_agreement" value="Y" :disabled="insuranceMode===1"> Yes</label><br>
                          </div>
                          <div class="">
                            <label class="form-label" for="agreeno">
                              <input type="radio" id="agreeno" name="updInsurance.team_has_any_agreement"
                                     v-model="updInsurance.team_has_any_agreement" value="N" :disabled="insuranceMode===1"> No</label><br>
                          </div>
                          <span class="font-13 text-danger"><i> (A copy of the document should be forwarded to USA-WSWS)</i></span>
                          <span class="text-sm text-danger">{{ errors.team_has_any_agreement }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group ">
                          <label class="form-label">Does the certificate holder require additional insured status? <sup class="text-danger">*</sup></label>
                          <div class="">
                            <label class="form-label" for="ins-status-yes">
                              <input type="radio" id="ins-status-yes" name="updInsurance.additional_insurance"
                                     v-model="updInsurance.additional_insurance" value="Y" :disabled="insuranceMode===1"> Yes</label><br>
                            <span class="text-sm text-danger">{{ errors.additional_insurance }}</span>
                          </div>
                          <div class="">
                            <label class="form-label" for="ins-status-no">
                              <input type="radio" id="ins-status-no" name="updInsurance.additional_insurance"
                                     v-model="updInsurance.additional_insurance" value="N" :disabled="insuranceMode===1"> No</label><br></div>
                        </div>
                        <div class="form-group " v-show="updInsurance.additional_insurance=='Y'">
                          <b-form-group label="Please check the relationship of the additional insured:">
                            <b-form-checkbox-group
                                id="additional_insurance_member"
                                v-model="updInsurance.additional_insurance_member"
                                :options="additionalInsuranceMemberOptions"
                                name="additional_insurance_member"
                                :disabled="insuranceMode===1"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    </form>
                  </div>
                </div>
              </b-modal>
              <b-modal
                  id="ski_sites_modal"
                  ref="modal"
                  :ok-title="skiSiteAct"
                  centered
                  title="Site Information"
                  @show="resetSkiModal"
                  @hidden="resetSkiModal"
                  @ok="updateSkiSites"
              >
                <form>
                  <div class="form-body row">
                    <input type="hidden" v-model="skiSites.extId" name="skiSitesId" />
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="school" class="form-label">Specific site/body of water of all club activities
                          <i class="text-danger">*</i></label>
                        <input type="text" class="form-control" maxlength="45" id="school" aria-describedby="school"
                            placeholder="Enter Specific site" v-model="skiSites.school" />
                        <span class="text-sm text-danger">{{ skiSitesErrors.school }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="address" class="form-label">Physical Address</label>
                        <input type="text" class="form-control" maxlength="45" id="address" v-model="skiSites.address" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="address" class="form-label">Address 2</label>
                        <input type="text" class="form-control" maxlength="45" id="address" v-model="skiSites.address2" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="state" class="form-label">State <i class="text-danger">*</i></label>
                        <select
                            class="form-control"
                            id="state"
                            name="form.state_code"
                            v-model="skiSites.state_code"
                        >
                          <option value="">Select</option>
                          <option
                              v-for="listOfState in listOfStates"
                              :key="listOfState.state"
                              :value="listOfState.state_code"
                          >{{ listOfState.state }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ skiSitesErrors.state_code }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="city" class="form-label">City <i class="text-danger">*</i></label>
                        <input type="text" class="form-control" maxlength="45" id="city" name="form.city" v-model="skiSites.city"/>
                        <span class="text-sm text-danger">{{ skiSitesErrors.city }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="zip" class="form-label">Zip/Postal Code <i class="text-danger">*</i></label>
                        <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip" name="form.zip" v-model="skiSites.zip"/>
                        <span class="text-sm text-danger">{{ skiSitesErrors.zip }}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </b-modal>
              <b-modal id="edit-club-contact" size="xl" ok-title="Update" @ok="updateClubContact" centered title="Contact Information" >
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12" v-if="UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.email">
                            <div class="form-group">
                                <label for="email" class="form-label">{{ prop_fields_contact.email.label }} <sup class="text-danger" v-if="prop_fields_contact.email.required">*</sup></label>
                                <input type="text" class="form-control" maxlength="45" id="email" v-model="UpdateCLub.email" name="form.email">
                                <span class="text-sm text-danger">{{ errors.email}}</span>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.retype_email">
                            <div class="form-group">
                                <label for="retype_email" class="form-label">{{ prop_fields_contact.retype_email.label }} <sup class="text-danger" v-if="prop_fields_contact.retype_email.required">*</sup></label>
                                <input type="text" class="form-control" maxlength="45" id="retype_email" v-model="UpdateCLub.retype_email" name="form.retype_email">
                                <span class="text-sm text-danger">{{ errors.retype_email}}</span>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="!isAllianceFastPitch && UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.mobilephone">
                            <div class="form-group ">
                                <div class="phone-nu-wrapper">
                                    <label for="country_code_h" class="form-label">{{ prop_fields_contact.mobilephone.label }} <sup class="text-danger" v-if="prop_fields_contact.mobilephone.required">*</sup></label>
                                    <vue-tel-input v-model="UpdateCLub.primary_contact.phone_1" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                    <span class="text-sm text-danger">{{ errors.mobilephone }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="isAllianceFastPitch && UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.homephone">
                            <div class="form-group ">
                                <div class="phone-nu-wrapper">
                                    <label for="country_code_h" class="form-label">{{ prop_fields_contact.homephone.label }} <sup class="text-danger" v-if="prop_fields_contact.homephone.required">*</sup></label>
                                    <vue-tel-input v-model="UpdateCLub.phone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                    <span class="text-sm text-danger">{{ errors.homephone }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="isAllianceFastPitch && UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.mobilephone">
                          <div class="form-group ">
                            <div class="phone-nu-wrapper">
                              <label for="country_code_h" class="form-label">{{ prop_fields_contact.mobilephone.label }} <sup class="text-danger" v-if="prop_fields_contact.mobilephone.required">*</sup></label>
                              <vue-tel-input v-model="UpdateCLub.phone_2" ref="phone" @validate="validateTelinput2"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                              <span class="text-sm text-danger">{{ errors.mobilephone }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12" v-if="!isAllianceFastPitch && UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_address">
                            <div class="form-group">
                                <label for="firstname" class="form-label">{{ prop_fields_contact.club_address.label }} <sup class="text-danger" v-if="prop_fields_contact.club_address.required">*</sup></label>
                                <input type="text" class="form-control" maxlength="100" id="club_address" v-model="UpdateCLub.primary_contact.address1" name="form.club_address">
                                <span class="text-sm text-danger">{{ errors.club_address }}</span>
                            </div>

                        </div>
                        <div class="col-md-4" v-if="!isAllianceFastPitch && UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_city">
                            <div class="form-group">
                                <label for="club_city" class="form-label">{{ prop_fields_contact.club_city.label }} <sup class="text-danger" v-if="prop_fields_contact.club_city.required">*</sup></label>
                                <input type="text" class="form-control" maxlength="45" id="club_city"  v-model="UpdateCLub.primary_contact.city" name="form.club_city">
                                <span class="text-sm text-danger">{{ errors.club_city }}</span>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="!isAllianceFastPitch && UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_state">
                            <div class="form-group">
                                <label for="club_state" class="form-label">{{ prop_fields_contact.club_state.label }} <sup class="text-danger" v-if="prop_fields_contact.club_state.required">*</sup></label>
                                <select class="form-control" id="club_state" v-model="UpdateCLub.primary_contact.state_code" name="form.club_state" >
                                    <option value="">Select</option>
                                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                </select>
                                <span class="text-sm text-danger">{{ errors.club_state }}</span>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="!isAllianceFastPitch && UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_zip">
                            <div class="form-group">
                                <label for="club_zip" class="form-label">{{ prop_fields_contact.club_zip.label }} <sup class="text-danger" v-if="prop_fields_contact.club_zip.required">*</sup></label>
                                <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="club_zip" v-model="UpdateCLub.primary_contact.zip" name="form.club_zip">
                                <span class="text-sm text-danger">{{ errors.club_zip }}</span>
                            </div>
                        </div>
                        <!-- Alliance FastPitch -->
                        <div class="col-md-12" v-if="isAllianceFastPitch && prop_fields_contact && prop_fields_contact.address1">
                          <div class="form-group">
                            <label for="address1" class="form-label">{{ prop_fields_contact.address1.label }} <sup class="text-danger" v-if="prop_fields_contact.address1.required">*</sup></label>
                            <input type="text" class="form-control" maxlength="100" id="address1" v-model="UpdateCLub.address" name="form.address1">
                            <span class="text-sm text-danger">{{ errors.club_address }}</span>
                          </div>

                        </div>
                        <div class="col-md-4" v-if="isAllianceFastPitch && prop_fields_contact && prop_fields_contact.country">
                          <div class="form-group">
                            <label for="city" class="form-label">{{ prop_fields_contact.city.label }} <sup class="text-danger" v-if="prop_fields_contact.city.required">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" id="city"  v-model="UpdateCLub.city" name="form.city">
                            <span class="text-sm text-danger">{{ errors.city }}</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="isAllianceFastPitch && prop_fields_contact && prop_fields_contact.country">
                          <div class="form-group">
                            <label for="city" class="form-label">{{ prop_fields_contact.country.label }} <sup class="text-danger" v-if="prop_fields_contact.country.required">*</sup></label>
                            <select class="form-control" id="country" @change="removeStateCode" v-model="UpdateCLub.country" name="UpdateProfile.country">
                              <option value="">Select</option>
                              <option :value="listOfCountry.country" v-for="listOfCountry in prop_fields_contact.country.options" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.country}}</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="isAllianceFastPitch && prop_fields_contact && prop_fields_contact.state_code">
                          <div class="form-group">
                            <label for="state_code" class="form-label">{{ prop_fields_contact.state_code.label }} <sup class="text-danger" v-if="prop_fields_contact.state_code.required">*</sup></label>
                            <select class="form-control" id="state_code" v-model="UpdateCLub.state_code" name="form.state_code" v-if="UpdateCLub.country == 'USA' || UpdateCLub.country == ''">
                              <option value="">Select</option>
                              <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                            </select>
                            <select class="form-control" id="state_code" v-model="UpdateCLub.state_code" name="form.state_code" v-else>
                              <option value="">Select</option>
                              <option  v-for="listOfState in prop_fields_contact.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.club_state }}</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="isAllianceFastPitch && prop_fields_contact && prop_fields_contact.zip">
                          <div class="form-group">
                            <label for="zip" class="form-label">{{ prop_fields_contact.zip.label }} <sup class="text-danger" v-if="prop_fields_contact.zip.required">*</sup></label>
                            <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip" v-model="UpdateCLub.zip" name="form.zip">
                            <span class="text-sm text-danger">{{ errors.club_zip }}</span>
                          </div>
                        </div>
                        <!-- End -->
                      </div>
                      <div class="row">
                        <div class="col-md-12" v-if="UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_physical_address">
                            <div class="form-group">
                                <label for="club_physical_address" class="form-label">{{ prop_fields_contact.club_physical_address.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_address.required">*</sup></label>
                                <div class="form-check mt-2 mb-3" v-if="prop_fields_contact.club_physical_address.same_check">
                                    <input type="checkbox" id="same_as_mailing_address" name="form.same_as_mailing_address" class="form-check-input" value="1" v-model="UpdateCLub.same_as_mailing_address" @change="changeSameAddress">
                                    <label for="same_as_mailing_address" class="d-flex mr-1">Same as Mailing Address</label>
                                </div>
                                <input type="text" class="form-control" maxlength="100" id="club_physical_address" v-model="UpdateCLub.primary_contact.physical_address" name="form.club_physical_address" :readonly="UpdateCLub.same_as_mailing_address">
                                <span class="text-sm text-danger">{{ errors.club_physical_address }}</span>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_physical_city">
                            <div class="form-group">
                                <label for="club_physical_city" class="form-label">{{ prop_fields_contact.club_physical_city.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_city.required">*</sup></label>
                                <input type="text" class="form-control" maxlength="45" id="club_physical_city"  v-model="UpdateCLub.primary_contact.physical_city" name="form.club_physical_city" :readonly="UpdateCLub.same_as_mailing_address">
                                <span class="text-sm text-danger">{{ errors.club_physical_city }}</span>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_physical_state">
                            <div class="form-group">
                                <label for="club_physical_state" class="form-label">{{ prop_fields_contact.club_physical_state.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_state.required">*</sup></label>
                                <select class="form-control" id="club_physical_state" v-model="UpdateCLub.primary_contact.physical_state_code" name="form.club_physical_state" :disabled="UpdateCLub.same_as_mailing_address">
                                    <option value="">Select</option>
                                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                </select>
                                <span class="text-sm text-danger">{{ errors.club_physical_state }}</span>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="UpdateCLub.primary_contact && prop_fields_contact && prop_fields_contact.club_physical_zip">
                            <div class="form-group">
                                <label for="club_physical_zip" class="form-label">{{ prop_fields_contact.club_physical_zip.label }} <sup class="text-danger" v-if="prop_fields_contact.club_physical_zip.required">*</sup></label>
                                <input type="text" class="form-control" maxlength="15" id="club_physical_zip" v-model="UpdateCLub.primary_contact.physical_zip" name="form.club_physical_zip" :readonly="UpdateCLub.same_as_mailing_address">
                                <span class="text-sm text-danger">{{ errors.club_physical_zip }}</span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="subdomain == 'shooting'">
                    <div class="row form-group col-md-12">
                      <label for="clubname" class="form-label"> Club Admin Individual USA Shooting Member ID #</label>
                      <div class="input-group">
                          <input type="number" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_member_id" name="UpdateCLub.club_admin_member_id" v-model="UpdateCLub.club_admin_member_id">
                          <div class="input-group-append">
                              <button class="btn btn-outline-primary" type="button" @click="searchMember"><i class="fa fa-search"></i> Search</button>
                          </div>
                      </div>
                        <span class="text-sm text-danger">{{ errors.club_admin_member_id }}</span>
                    </div>
                      <label class="text-danger" v-if="clubAdminSearch.searched && UpdateCLub.club_admin_name == ''">No Member Found with Member ID #{{ clubAdminSearch.memberIDSearched }}</label>
                      <div class="row form-group col-md-12">
                          <label for="clubname" class="form-label">Club Admin Name <sup class="text-danger">*</sup></label>
                          <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_name" name="UpdateCLub.club_admin_name" v-model="UpdateCLub.club_admin_name" readonly :placeholder="UpdateCLub.club_admin_name.placeholder">
                          <span class="text-sm text-danger">{{ errors.club_admin_name }}</span>
                      </div>
                  </div>
                </div>
              </b-modal>
              <b-modal id="changeDiscipline" title="Change Discipline" @ok="changeDiscipline" @hidden="resetDisciplineData" ok-title="Update">
                <b-form-select v-model="changeDisciplineData.sports_discipline" :state="DonationSportDisciplineDpDwn" @change="checkDiscipline()">
                  <b-form-select-option v-for="(SportDiscipline, id) in  sortedDonations(listOfDonationSportDiscipline)" :value="SportDiscipline.id" :key="id">
                    {{ SportDiscipline.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-modal>
              <b-modal id="playerTransfer" ref="playerTransfer" title="Player Transfer"
                       @show="resetPlayerTransfer"
                       @hidden="resetPlayerTransfer"
                       @ok="handlePlayerTransfer"
              >
                <form ref="form" id="player-transfer-form">
                  <div class="form-group">
                    <label for="league" class="form-label">Leagues <sup class="text-danger">*</sup></label>
                    <select class="form-control" v-model="playerTransferForm.league" id="league" @change="getTeam()" >
                      <option value="">Select</option>
                      <option  v-for="(leagueData,key) in teamLeague" :key="key" :value="leagueData.id">{{ leagueData.name }}</option>
                    </select>
                    <span class="text-sm text-danger league-error"></span>
                  </div>

                  <div class="form-group">
                    <label for="age_division" class="form-label">Division <sup class="text-danger">*</sup></label>
                    <select class="form-control" @change="getTeam()" id="age_division" v-model="playerTransferForm.age_division"  name="playerTransferForm.age_division" :key="ageKey">
                      <option value="">Select</option>
                      <option  v-for="(listOfAgeDivision,key) in divisions_list" :key="key"  :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">{{ listOfAgeDivision.text }}</option>
                    </select>
                    <span class="text-sm text-danger division-error"></span>
                  </div>

                  <div class="form-group">
                    <label for="leagueTeam" class="form-label">Team <sup class="text-danger">*</sup></label>
                    <select class="form-control" id="leagueTeam" v-model="playerTransferForm.team" >
                      <option value="">Select</option>
                      <option value="wait">Wait List</option>
                      <option value="free">Free Agents</option>
                      <option  v-for="(TeamData,key) in leagueTeam" :key="key" :value="TeamData.id">{{ TeamData.text }}</option>
                    </select>
                    <span class="text-sm text-danger team-error"></span>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" v-model="playerTransferForm.notify" type="checkbox" value="" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                      Notify
                    </label>
                  </div>
                </form>
              </b-modal>

            </div>
        </div>
    </div>
</template>

<script>
import ClubRoastersComponent from "../Member/Dashboard/profile/ClubRoastersComponent.vue";
import * as $ from "jquery";
import ClubCard from "./../club/dashboard/profile/clubcard.vue";
import moment from "moment";
import axios from 'axios';
import CustomAlert from "../CustomAlert";
import MemberLookup from "../club/dashboard/components/MemberLookup";
import ChangeRegExpirationDate from './changeRegExpirationDate';
import StaffList from "./Team/StaffList";
const validateRadio = radio => {
  if (radio == undefined) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateName = (name, fieldName = '',isName = false) => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if(isName) {
    if (!names.match(/[A-Za-z? ( ) -]+$/)) {
      return {valid: false, error: fieldText + " must be letters only. No other characters"};
    }
  }
  return { valid: true, error: null };
};
const validateStateName = name => {
    console.log('v state');
    console.log(name);
    if (name == null || name == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateSelect = select => {
    console.log('lenghth='+select);
    if (!select) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateCheck = choices => {
    if(choices.length) return { valid: true, error: null };
    return { valid: false, error: "This field is required" };
}
const validatePhone = (phone, validTelinput, phoneField= '' ) => {
    let phoneText = ( phoneField != '' ? phoneField : "This field");
    if (!phone) {
        return { valid: false, error: phoneText+' is required .' };
    }
    if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
    }
    return { valid: true, error: null };
}

const validateEmail = (email, fieldEmail='') => {
  let emailText= (fieldEmail != '' ? fieldEmail : "This field");
  if (!email.length) {
    return { valid: false, error: emailText+" is required" };
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Please enter a valid email." };
  }
  return { valid: true, error: null };
};

const validateZip = (zipCode, country, fieldName = '') => {
    let zipText= (fieldName != '' ? fieldName : "Zip code");
    if( zipCode == undefined || !zipCode.length || zipCode.trim() == "")
    {    
         return { valid: false, error: zipText+" is required" };
    }
    else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
        return { valid: false, error: zipText+" Should Be 5 Number Values for USA." };
    }
    else if(zipCode.length < 5 && country == 'USA' ){
         return { valid: false, error: zipText+" should be 5 character" };
    }
      return { valid: true, error: null };
};

const validateNonMandatoryZip = (zipCode, fieldName = '') => {
  let zipText= (fieldName != '' ? fieldName : "Zip code");
  if(zipCode != undefined && zipCode.length && zipCode.trim() != "" && !zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: zipText+" Should Be 5 Number Values for USA." };
  }
  return { valid: true, error: null };
};

const validateAddressName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  return { valid: true, error: null };
};
const validatePhone2 = (phone, validTelinput2, phoneField= '' ) => {
  if(validTelinput2 == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}

export default {
    name: 'ClubUserProfile',
    components: {StaffList, ClubRoastersComponent, ClubCard, CustomAlert, MemberLookup, ChangeRegExpirationDate,},

  data() {
    return {
      isProfileloading: false,
      selectedNote:'',
      notes:'',
      edit_index: '',
      member_id:'',
      edit_note_id:'',
      userNote:'',
      Notetype: {},
      notestatus:1,
      errors: {},
      updateLoggedUserInfo:0,
      clubuserProfileData: '',
      regDate: '',
      expiryDate: '',
      profileImg: '/dist/img/profile1.png',
      tblSiteFields: [
        {key: 'school', sortable: true, label: 'Specific site/body of water of all club activities'},
        {key: "primary_club", sortable: true, label: "Primary Site" },
        {key: 'address', sortable: true, label:"Physical Address", formatter: 'formatName'},
        {key: 'address2', sortable: true, formatter: 'formatName'},
        {key: 'city', sortable: true, formatter: 'formatName'},
        {key: 'usa_state', sortable: true, label: "State", formatter: 'formatName'},
        {key: 'zip', sortable: true, formatter: 'formatName'},
        {key: 'actions', sortable: false},
      ],
      membershipFields: [
        {key: 'membership.name', sortable: true, label: 'Registration Type'},
        {key: 'created_at', sortable: true, label: 'Registered Date', formatter: 'formatDate'},
        {key: 'transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName' },
        {key: 'transaction_date', sortable: true, label: 'Transaction Date', formatter: "formatlongDate"},
        {key: 'status', sortable: true, label: 'Status'},
        {key: 'start_date', sortable: true, label: 'Effective From', formatter: 'formatDate'},
        {key: 'valid_thru', sortable: true, label: 'Effective To', formatter: 'formatDate'},
        {key: 'waiver', sortable: false, label: 'Waiver'},
        {key: 'receipt', sortable: false, label: 'Receipt'},
      ],
      donationTbl: [
        {key: 'sports_discipline', sortable: true, label: 'Donation Discipline', formatter: 'formatName'},
        {key: 'cost', sortable: true, label: 'Amount', formatter: 'formatName'},
        {key: 'transaction_code', sortable: true, label: 'Transaction Id',formatter: 'formatName'},
        {key: 'transaction_time', sortable: true, label: 'Transaction Date', formatter: 'formatlongDate'},
        {key: 'active', sortable: true, label: 'Status'},
        { key: 'receipt', sortable: false, label: 'Receipt'},
      ],
      tblFields: [
        { key: 'membership_id', sortable: true, label: 'Member #' },
        {  key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'city', sortable: true, label: 'City' },
        { key: 'state_code', sortable: true, label: 'State' },
        { key: 'memStatus', sortable: false, label: 'Membership Status' },
        { key: 'waiverStatus', sortable: false, label: 'Waiver Status' },
        { key: 'ssStatus', sortable: false, label: 'Safe Sport Training Status' },
        { key: 'bgStatus', sortable: false, label: 'Background Screen Status' },
        { key: 'mvrStatus', sortable: false, label: 'MVR Status' },
        { key: 'actions', sortable: false },
      ],
      insuranceList: [],
      insuranceListKey: 0,
      viewInsurancePrevious: false,
      tblInsuredFields: [
        { key: 'company_name', sortable: true, label: 'Company Name' },
        { key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'transaction_code', sortable: true, label: 'Transaction Id' },
        { key: 'start_date', sortable: true, label: 'Start Date', formatter: 'usdate' },
        { key: 'end_date', sortable: true, label: 'End Date', formatter: 'usdate' },
        { key: 'transaction_time', sortable: true, label: 'Purchased Date', formatter: 'usdate' },
        { key: 'fulfilled', sortable: false },
        { key: 'fulfilled_date', sortable: false, formatter: 'usdate' },
        { key: 'actions', sortable: false },
      ],
      sanctionList: [],
      sanctionListKey: 0,
      viewSanctionPrevious: false,
      tblSanctionFields: [
        { key: 'sanction_number', sortable: true, label: 'Sanction Number' },
        { key: 'guest_registration_url', sortable: true, label: 'Guest Registration Url' },
        { key: 'cost', sortable: true, label: 'Cost' },
        { key: 'transaction_code', sortable: true, label: 'Transaction Id' },
        { key: 'registration.start_date', sortable: true, label: 'Start Date', formatter: 'usdate' },
        { key: 'registration.valid_thru', sortable: true, label: 'End Date', formatter: 'usdate' },
        { key: 'transaction_time', sortable: true, label: 'Purchased Date', formatter: 'usdate' },
        { key: 'waiver', sortable: false, label: 'Waiver'},
        /*{ key: 'actions', sortable: false },*/
      ],
      notesTbl:[
        {key: 'note', sortable: true, label: 'Notes'},
        {key: 'note_type', sortable: true, label: 'Note Type', formatter: 'getNoteTypeName'},
        {key: 'status', sortable: true, label: 'Status', thClass: 'd-none', tdClass: 'd-none'},
        {key: 'name', sortable: true, label: 'Created By', formatter: 'formatName'},
        {key: 'note_date', sortable: true, label: 'Date', formatter: 'formatName'},
        {key: "actions", sortable: false}
      ],
      UpdateCLub: {
        type: "",
        name: "",
        school: "",
        description: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        website: "",
        secondary_type: "",
        state_code: "",
        exclude: "",
        email: "",
        retype_email: "",

        visibility_type: "",
        club_type: [],
        club_disciplines: [],
        club_admin_member_id: "",
        club_admin_name: "",
        same_as_mailing_address: "",
        valid_thru:"",
        start_date:"",
        phone:"",
        phone_2:"",
        memId: '',
      },
      contactType: "",
      editContactType:"",
      skiSiteAct: "Add Ski-Sites",
      skiSites: {
        school: "",
        address: "",
        address2: "",
        country: "",
        state: "",
        state_code: "",
        city: "",
        zip: "",
        extId: "",
      },
      skiSitesErrors: {
        school: "",
        country: "",
        state: "",
        state_code: "",
        city: "",
        zip: "",
      },
      prop_fields: null,
      prop_fields_contact: null,
      insuranceErrors: {},
      insuranceForm: {
        first_name: '',
        last_name: '',
        company_name: '',
        insurance_type: '',
        home_phone: '',
        home_phone_country_code: '',
        home_phone_extension: '',
        work_phone: '',
        work_phone_country_code: '',
        work_phone_extension: '',
        fax: '',
        address1: '',
        address2: '',
        city: '',
        state_code: '',
        state: '',
        zip: '',
        country: '',
        certificate_type: '',
        sanctioned_type: '',
        event_sanction: '',
        specific_event_title: '',
        start_date: '',
        end_date: '',
        team_has_any_agreement: '',
        additional_insurance: '',
        additional_insurance_member: [],
        index: '',
        team_id: '',
        registration_id: '',
      },
      autoRenew: 0,
      autoRenewData: {
        regId: "",
        status: "",
      },
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      options: { placeholder: "Phone Number" },
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Home Number",
        maxLen: 20,
      },
      clubAdminSearch: {
          searched: false,
          memberIDSearched: ''
      },
      validTelinput: false,
      mobileProps: {
          mode: "international",
          defaultCountry: "US",
          placeholder: "Mobile Number",
          maxLen: 20,
      },
      isExpired:"",
      insuranceMode:0,
      updInsurance:{
        additional_insurance: "",
        additional_insurance_member: [],
        address1: "",
        address2: "",
        certificate_type: "",
        city: "",
        company_name: "",
        country: "",
        created_at: "",
        deleted_at: "",
        event_sanction: "",
        fax: "",
        first_name: "",
        home_phone: "",
        home_phone_country_code: "",
        home_phone_extension: "",
        id: "",
        insurance_type: "",
        insurer_name: "",
        last_name: "",
        reference_code: "",
        registration_id: "",
        sanctioned_type: "",
        start_date: "",
        end_date: "",
        specific_event_title: "",
        state: "",
        state_code: "",
        team_has_any_agreement: "",
        team_id: "",
        transaction_code: "",
        transaction_time: "",
        updated_at: "",
        work_phone: "",
        work_phone_country_code: "",
        work_phone_extension: "",
        zip: "",
        fulfilled: "",
      },
      changeDisciplineData: {
        'sports_discipline':'',
        'id':'',
      },
      note:'',
      DonationSportDisciplineDpDwn:true,
      valid: false,
      notACompany: false,
      refreshProgress: false,
      noteProgress: false,
      purchaseHistoryTbl:[
        {key: 'transaction_date', sortable: true, label: 'Transaction Date',
            formatter: (value) => {
            return moment(String(value)).format("MM/DD/YYYY");
            }
        },
        {key: 'transaction_id', sortable: true, label: 'Transaction ID'},
        {key: 'product', sortable: true, label: 'Product', 
        formatter: (value , key , item) => {
            let couponValue = value + ' ('+item.name+')';
            return item.name != null && item.name.includes('Discount Coupon') ? couponValue : value;
            }
        },
        {key: 'cost', sortable: false, label: 'Cost', 
            formatter: (value) => {
            return '$'+value;
            }
        },
        { key: 'receipt', sortable: false, label: 'Receipt'},
      ],
      purchaseHistory : {
          data: [],
          totalRows: 1,
          loaded: false,
          perPage: 10,
          currentPage: 1,
          filter: {
              search: '',
              startDate: '',
              endDate: '',
          },
          filterOn: [],
          viewEntryOptions: [
              { value: 5, text: "5" },
              { value: 10, text: "10" },
              { value: 20, text: "20" },
              { value: 50, text: "50" }
        ]
      },
      excludeExpiredRoster: true,
      tblrosterFields: [
          { key: 'first_name', sortable: true, label: 'First Name',sortByFormatted: true, formatter: (value, key, item) => item.personal_details.first_name  },
          { key: 'last_name', sortable: true, label: 'Last Name',sortByFormatted: true, formatter: (value, key, item) => item.personal_details.last_name  },
          { key: 'confirmation_code', sortable: true, label: 'Member #' },
          { key: 'membership_name', sortable: true, label: "Membership Type",sortByFormatted: true, formatter: (value, key, item) => item.latest_registration.membership.name  },
          { key: 'membership_endtate', sortable: true, label: "Membership Expiration", sortByFormatted: true, formatter: (value, key, item) => item.latest_registration.valid_thru },
          { key: 'member_status', sortable: true, label: 'Membership Status',sortByFormatted: true, formatter: (value, key, item) => item.status_text  },
          { key: 'roster_status', sortable: true, label: 'Roster Status',sortByFormatted: true, formatter: (value, key, item) => item.roster_status  },
          { key: 'actions', sortable: false },
      ],
      clubAdditionalInfoView :true,
      ageDivisionOPt:[
        {"id":"4","text":"12U"},
        {"id":"5","text":"10U"}
      ],
      teamLeague: {},
      leagueTeam: [],
      playerTransferForm:{
        league:'',
        team:'',
        notify:'',
        member_id:'',
        age_division:'',

      },
      rostersInfo:[],
      disciplines:[],
      teamId:'',
      rosterStatus:{
        1:'Active',
        2:'Wait List',
        3:'Free Agent',
        4:'Pending Transfer'
      },
      disciplines_list:[],
      loaded:true,
      isTransferEnable:0,
      isAllianceFastPitch:false,
      alertMessage: "",
      displayAlert: "",
      displayMissingMemFields: false,
      staffInfo:[],
      staffloaded:true,
      divisions_lists:[],
      club_reg_status:"Current",
      divisions_list:[],
      ageKey:0,
      teamOptions:false,
    }
  },

    methods: {
        formatDate(value)
        {
            if(value){
                return moment(value).format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },

        formatlongDate(datevalue)
        {
            var date = moment(datevalue);
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },

      formatName(value) {
      return value || 'N/A'
    },
        userProfileApi(isProfileloading = true){
          this.isProfileloading = isProfileloading;
            this.regId = this.$route.params.regId;
            this.teamId = this.$route.params.regId;
                axios.get(this.basePath+'api/registration/club/'+ this.regId, { headers:this.adminHeaders })
                    .then((res) => {
                      this.isProfileloading = false;
                            this.clubuserProfileData = res.data.data;
                            this.rostersInfo = this.clubuserProfileData.roster;
                            this.staffInfo = this.clubuserProfileData.staff;
                            this.regDate = this.formatlongDate(this.clubuserProfileData.member.start_date);
                            this.club_reg_status = this.clubuserProfileData.registration.reg_status;
                            this.expiryDate = this.formatlongDate(this.clubuserProfileData.member.valid_thru);
                      this.UpdateCLub.start_date = moment(this.clubuserProfileData.member.start_date).format('MM/DD/YYYY');
                      this.UpdateCLub.valid_thru = moment(this.clubuserProfileData.member.valid_thru).format('MM/DD/YYYY');
                     const current = new Date();
                      const date = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;
                      this.isExpired = moment(date).isAfter(this.expiryDate);
                            this.autoRenew = res.data.data.auto_renew;
                            this.autoRenewData.regId = res.data.data.registration.id;
                            this.autoRenewData.status = res.data.data.auto_renew;
                      for (let prop in this.clubuserProfileData) {
                        if (Object.prototype.hasOwnProperty.call(this.UpdateCLub, prop)) {
                          this.UpdateCLub[prop] = this.clubuserProfileData[prop];
                        }
                      }
                      this.UpdateCLub.memId = this.UpdateCLub.club_admin_member_id;
                      this.UpdateCLub.name = this.clubuserProfileData.club_name;
                      this.UpdateCLub.league = this.clubuserProfileData.memberLeageue.id;
                      this.UpdateCLub.member_league = this.clubuserProfileData.memberLeageue.id;
                      this.UpdateCLub.type = this.clubuserProfileData.type;
                      this.UpdateCLub.agl_team_id = (this.clubuserProfileData.agl_team_id != null || this.clubuserProfileData.agl_team_id != '')?this.clubuserProfileData.agl_team_id:'';
                      if(this.clubuserProfileData.ageDivision != null){
                        this.UpdateCLub.age_division = this.clubuserProfileData.ageDivision.id;
                        this.UpdateCLub.age_division_name = this.clubuserProfileData.ageDivision.name;
                      }else{
                        this.clubuserProfileData.ageDivision = {id:""};
                        this.UpdateCLub.age_division = '';
                      }

                      if(Object.prototype.hasOwnProperty.call(this.UpdateCLub, 'club_type') && this.clubuserProfileData.club_type != null)
                        this.UpdateCLub.club_type = this.clubuserProfileData.club_type.split(",");

                      if(Object.prototype.hasOwnProperty.call(this.UpdateCLub, 'club_disciplines') && Array.isArray(this.UpdateCLub.club_disciplines) && this.UpdateCLub.club_disciplines.length)
                        this.UpdateCLub.club_disciplines = this.UpdateCLub.club_disciplines.map(item => item.id);

                      if(Object.prototype.hasOwnProperty.call(this.UpdateCLub, 'retype_email'))
                        this.UpdateCLub.retype_email = this.clubuserProfileData.email;

                      this.UpdateCLub['primary_contact'] = this.clubuserProfileData['primary_contact'];
                      if(this.UpdateCLub.primary_contact != undefined && this.clubAdditionalInfoView){
                        this.UpdateCLub.primary_contact.state_code = this.UpdateCLub.primary_contact.state_code || this.UpdateCLub.state_code;
                      }
                      this.loadTeamInsurances();
                      this.loadClubSanctions();
                      this.loadRostersInfo();
                      this.fetchnote();
                      this.loadPurchaseHistory();
                      //alliance fast pitch profile handler
                      if(!this.getSiteProps('general.member_profile_addition_info') && this.getSiteProps('general.member_profile_addition_info') != undefined){
                        this.clubAdditionalInfoView = false;
                        if(this.membership_id != 4){
                          this.ageDivisionOPt = this.disciplines_list;
                        }
                      }
                      if(this.subdomain == "alliancefastpitch"){
                        if(this.UpdateCLub.name == null){
                          this.displayMissingMemFields = true;
                          this.alertMessage = "Please fill out the Team Name #";
                        }
                        this.playerTransferForm.league = this.UpdateCLub.league;
                        this.playerTransferForm.age_division=this.UpdateCLub.age_division;
                        this.getTeam();
                      }
                    })
                    .catch((error) => {
                      this.isProfileloading = false;
                        console.log('Error:'+ error);
                        return false;
                    }).finally(() => {
                });
        },
      loadTeamInsurances() {
        this.insuranceList = [];

        if (this.clubuserProfileData?.insured_members?.length) {
          const currentYear = moment().year();

          this.insuranceList = this.clubuserProfileData.insured_members
              .filter(member => {
                const endDateYear = moment(member.end_date).year();
                return currentYear <= endDateYear;
              });

          this.insuranceList = this.viewInsurancePrevious ? this.clubuserProfileData?.insured_members : this.insuranceList;
          this.insuranceListKey++;
        }
      },
      loadClubSanctions() {
        this.sanctionList = [];
        if (this.clubuserProfileData?.club_sanction_data?.length) {
          const currentYear = moment().year();

          this.sanctionList = this.clubuserProfileData.club_sanction_data
              .filter(member => {
                const endDateYear = moment(member.end_date).year();
                return currentYear <= endDateYear;
              });
          this.sanctionList = this.viewSanctionPrevious ? this.clubuserProfileData?.club_sanction_data : this.sanctionList;
          this.sanctionListKey++;
        }
      },
      delTeamSites(id) {
        this.displayAlert = false;
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete the Club Ski-Site.', {
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
            .then(value => {
              if (String(value) == "true") {
                axios.delete(this.basePath + 'api/club/del_ski_site/' + id, {headers: this.adminHeaders})
                    .then(function () {
                      this.alertMessage = "Club Ski-Site deleted successfully";
                      this.displayAlert = true;
                      this.userProfileApi();
                    }.bind(this))
                    .catch(function (error) {
                      console.log(error);
                    });
              }
            })
            .catch(err => {
              console.log(err);
            })
      },
      updateClubInfo(bvModalEvt) {
        bvModalEvt.preventDefault()
        if(this.validate()){
          this.displayAlert = false;
          axios
          .post(this.basePath + "api/update/club/"+this.clubuserProfileData.id, this.UpdateCLub, { headers:this.adminHeaders })
          .then(
              function(response) {
                this.alertMessage = this.prop_fields.club_info.tab_title +" updated successfully";
                this.displayAlert = true;
                this.updateLoggedUserInfo += 1;
                this.userProfileApi();
              }.bind(this)
          )
          .catch(function(error) {
            console.log(error);
          });
          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide('modal-center1')
          });
        }
      },
      updateClubContact(bvModalEvt){
        bvModalEvt.preventDefault();
        this.isProfileloading = true;
        if(this.validateContact()){
          if(!this.isAllianceFastPitch) {
            this.UpdateCLub.address = this.UpdateCLub.primary_contact.address1;
            this.UpdateCLub.city = this.UpdateCLub.primary_contact.city;
            this.UpdateCLub.state_code = this.UpdateCLub.primary_contact.state_code;
            this.UpdateCLub.zip = this.UpdateCLub.primary_contact.zip;
            this.UpdateCLub.club_admin_member_id = this.UpdateCLub.memId;
          }
          axios
          .post(this.basePath + "api/update/club/"+this.clubuserProfileData.id, this.UpdateCLub, { headers:this.adminHeaders })
          .then(
              function(response) {
                this.alertMessage = this.prop_fields.club_info.tab_title +" updated successfully";
                this.displayAlert = true;
                this.updateLoggedUserInfo += 1;
                this.userProfileApi();
              }.bind(this)
          )
          .catch(function(error) {
            console.log(error);
          });
          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide('edit-club-contact')
          });
        }
      },
      downloadDonarionDocs(item) {
        this.downloadPdf(this.basePath + 'api/club/donation/'+item, 'Donation');
      },
      downloadDocs(item, type) {
        this.downloadPdf(this.basePath + 'api/registration/download/'+type+'/'+item, 'Reg'+item+"_"+type );
      },
      downloadSanctionDocs(item, type) {
        this.downloadPdf(this.basePath + 'api/club/sanction-download/'+type+'/'+item, 'Reg'+item+"_"+type );
      },
      downloadOtherDocs(item, type, transaction_id){
        this.downloadPdf(this.basePath + 'api/registration/clubreceiptDownload/'+type+'/'+item+'/null/'+transaction_id, 'Reg'+item+"_"+type );
      },
      downloadPdf(url, name){
        axios.get(url, {headers:this.adminHeaders, responseType: 'arraybuffer'})
            .then(response => {
              let blob = new Blob([response.data], { type: 'application/pdf' });
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = this.clubuserProfileData.club_name+"_"+name+'.pdf';
              link.click();
              link.remove();
            }).catch((error)=>{
          console.log(error);
        });
      },
      setContactType(type) {
        this.contactType = type;
        this.editContactType = type;
      },
      deleteRow(id){
        this.displayAlert = false;
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete the Club Officials.', {
            title: 'Please Confirm',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if(String(value)=="true") {
                    axios.delete(this.basePath+'api/club/del_contacts/'+id,{headers: this.clubHeaders})
                        .then(function (response) {
                            this.alertMessage = "Club member deleted successfully";
                            this.displayAlert = true;
                            this.userProfileApi();
                        }.bind(this))
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
            .catch(err => {
                console.log(err);
            })
      },
      ChangeClubMember(data) {
        data.contact_type = this.contactType;
        this.displayAlert = false;
        axios.post(this.basePath + "api/update/club/contacts/"+this.clubuserProfileData.id, data, {headers: this.adminHeaders})
            .then(function (response) {
                  this.alertMessage = "Club member changed successfully";
                  this.displayAlert = true;
                  this.userProfileApi();
                }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      },
      setVal(title, id = 0) {
        this.skiSiteAct = title;
        if (id !== 0) {
          axios
              .get(this.basePath + "api/club/get_ski_site/" + id, {headers: this.adminHeaders})
              .then(
                  function(response) {
                    var resp = response.data.data;
                    this.skiSites.school = resp.school;
                    this.skiSites.address = resp.address;
                    this.skiSites.address2 = resp.address2;
                    this.skiSites.country = resp.country;
                    this.skiSites.state = resp.state;
                    this.skiSites.state_code = resp.state_code;
                    this.skiSites.city = resp.city;
                    this.skiSites.zip = resp.zip;
                    this.skiSites.extId = resp.id;
                  }.bind(this)
              )
              .catch(function(error) {
                console.log(error);
              });
        } else {
          this.resetSkiModal();
        }
      },
      resetSkiModal() {
        this.skiSites.school = "";
        this.skiSites.address = "";
        this.skiSites.country = "";
        this.skiSites.state = "";
        this.skiSites.state_code = "";
        this.skiSites.city = "";
        this.skiSites.zip = "";
        this.skiSites.extId = "";
      },
      updateSkiSites(evt) {
        evt.preventDefault();
        if (!this.skiSiteValidate()) {
          return;
        } else {
          axios
              .post(this.basePath + "api/club/ski_site/"+this.clubuserProfileData.id, this.skiSites, {headers: this.adminHeaders})
              .then(
                  function(response) {
                    this.alertMessage = "Club Ski-Site updated successfully";
                    this.displayAlert = true;
                    this.userProfileApi();
                  }.bind(this)
              )
              .catch(function(error) {
                console.log(error);
              });
          this.$nextTick(() => {
            this.$bvModal.hide("ski_sites_modal");
          });
        }
      },
      skiSiteValidate() {
        let error = "";
        this.skiSitesErrors.school =
            this.skiSites.school == "" ? "Enter valid ski-site name" : "";
        error += this.skiSites.school == "" ? "err" : "";
          this.skiSitesErrors.state_code =
              this.skiSites.state_code == ""
                  ? "Select valid state for Ski-Site"
                  : "";
          error += this.skiSites.state_code == "" ? "err" : "";
        this.skiSitesErrors.city =
            this.skiSites.city == "" ? "Select valid state for Ski-Site" : "";
        error += this.skiSites.city == "" ? "err" : "";
        if(this.skiSites.zip == ""){
          this.skiSitesErrors.zip = "Zip code is required";
          error += "err";
        }
        else if(!this.skiSites.zip.match(/^\d{5}(-\d{4})?$/)){
          this.skiSitesErrors.zip = "Zip Code Should Be 5 Number.";
          error += "err";
        }
        else{
          this.skiSitesErrors.zip = "";
          error += "";
        }
        console.log(error);
        return error == "";
      },
      isFutureDate(date) {
        const currentDate = new Date();
        return date < currentDate;
      },
      //Modals
      resetModal() {
        this.uploadLogoFile = "";
        this.preview = "";
      },
      resetInfoModal() {
          console.log('resseet');
        this.updInsurance = {
          additional_insurance: "",
          additional_insurance_member: [],
          address1: "",
          address2: "",
          certificate_type: "",
          city: "",
          company_name: "",
          country: "",
          created_at: "",
          deleted_at: "",
          event_sanction: "",
          fax: "",
          first_name: "",
          home_phone: "",
          home_phone_country_code: "",
          home_phone_extension: "",
          id: "",
          insurance_type: "",
          insurer_name: "",
          last_name: "",
          reference_code: "",
          registration_id: "",
          sanctioned_type: "",
          start_date: "",
          end_date: "",
          specific_event_title: "",
          state: "",
          state_code: "",
          team_has_any_agreement: "",
          team_id: "",
          transaction_code: "",
          transaction_time: "",
          updated_at: "",
          work_phone: "",
          work_phone_country_code: "",
          work_phone_extension: "",
          zip: "",
          fulfilled: "",
        };
      },
      viewRow(item, index, button) {
        this.insuranceMode = 1;
        for (let prop in item) {
          if ( Object.prototype.hasOwnProperty.call(this.updInsurance, prop) ) {
            this.updInsurance[prop] = item[prop]
          }
        }
        this.updInsurance.start_date = moment(this.updInsurance.start_date).format("MM/DD/YYYY");
        this.updInsurance.end_date = moment(this.updInsurance.end_date).format("MM/DD/YYYY");
        this.$root.$emit("bv::show::modal", 'updateClubInsurance', button);
      },
      editRow(item, index, button) {
        this.insuranceMode = 0;
        for (let prop in item) {
          if ( Object.prototype.hasOwnProperty.call(this.updInsurance, prop) ) {
            this.updInsurance[prop] = item[prop]
          }
        }
        this.updInsurance.start_date = moment(this.updInsurance.start_date).format("MM/DD/YYYY");
        this.updInsurance.end_date = moment(this.updInsurance.end_date).format("MM/DD/YYYY");
        this.$root.$emit("bv::show::modal", 'updateClubInsurance', button);
      },
      deleteInsurance(i) {
        this.displayAlert = false;
        console.log("remove index="+i);
        let removeInsurance = {};
        removeInsurance.insurance_id = i;
        axios
            .post(this.basePath + "api/remove_club_insurance", removeInsurance, {headers: this.adminHeaders})
            .then(
                function(response) {
                  this.alertMessage = "Club Insurance deleted successfully";
                  this.displayAlert = true;
                  this.userProfileApi();
                }.bind(this)
            )
            .catch(function(error) {
              console.log(error);
            });
      },
       getNoteTypeName(value){
          var key = 'Notetype.disciplines.options.'+value;
          var typName = this.getSiteProps(key);
          return typName || "N/A";
      },
      changeAutoRenewal(val) {
          this.$bvModal
              .msgBoxConfirm("Are you sure want to change the Auto-Renewal?", {
                title: "Please Confirm",
                size: "md",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "YES",
                cancelTitle: "NO",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              })
              .then((value) => {
                  this.autoRenewData.status = val;
                  axios
                      .post(
                          this.basePath + "api/club/auto-renew-status",
                          this.autoRenewData,
                          { headers: this.clubHeaders }
                      )
                      .then(
                          function(response) {
                            this.userProfileApi();
                            this.alertMessage = "Auto renewal changed successfully";
                            this.displayAlert = true;
                          }.bind(this)
                      )
                      .catch(function(error) {
                        console.log(error);
                      });
              })
              .catch((err) => {
                console.log(err);
              });
      },
      loadPropFields(){
        const retry = setInterval(()=>{
          if(this.subdomain == 'alliancefastpitch')
          {
            this.prop_fields = this.getSiteProps('memberRegistration.team.fields');
            if(this.prop_fields) clearTimeout(retry);
          }
          else{
            this.prop_fields = this.getSiteProps('memberRegistration.club.fields');
            if(this.prop_fields) clearTimeout(retry);
          }
        }, 100);

        const retry1 = setInterval(() => {
        this.Notetype = this.getSiteProps('Notetype.disciplines');              
        if(this.Notetype != "") clearInterval(retry1);
      }, 200);
      },
      displayAlertAndReloadData(response) {
        this.alertMessage = response.message;
        this.displayAlert = true;
        this.userProfileApi();
        this.updateLoggedUserInfo += 1;
      },
      triggerSelectDisciplinesAll(event){
          if(event.target.checked){
              var ids = Object.keys(this.clubDisciplinesList);
              if(ids.length){
                  ids.forEach(id => {
                      this.UpdateCLub.club_disciplines.push(id);
                  });
              }
          } else {
              this.UpdateCLub.club_disciplines = [];
          }
      },
      searchMember(){
        if(this.UpdateCLub.club_admin_member_id == '') {
          this.errors.club_admin_member_id = "Please enter the member_id";
          this.errorUpdateKey++;
        }
          this.clubAdminSearch.searched = false;
          this.clubAdminSearch.memberIDSearched = "";
          if(this.UpdateCLub.club_admin_member_id){
              axios.get(this.basePath + "api/member_lookup?member_id="+this.UpdateCLub.club_admin_member_id).then(res => {
                  if(res.data.data != undefined && res.data.data.length){
                      this.UpdateCLub.memId = this.UpdateCLub.club_admin_member_id;
                      this.errors.club_admin_name = "";
                      this.UpdateCLub.club_admin_name = res.data.data[0].personal_details.full_name
                  } else {
                      this.UpdateCLub.club_admin_name = "";
                  }
              }).catch(err => {
                  console.log(err);
                  this.clubAdminSearch.searched = true;
                  this.clubAdminSearch.memberIDSearched = this.UpdateCLub.club_admin_member_id;

              }).finally(() => {
                  this.clubAdminSearch.searched = true;
                  this.clubAdminSearch.memberIDSearched = this.UpdateCLub.club_admin_member_id;
              });
          }
      },
      validate() {
          this.errors = {}
          this.valid = false;
          var clubType = '', clubEmail = '', clubAddress = '', clubCity = '', clubStateCode = '', clubZip = '', clubAgeDivision = '';

          const validName = validateName(this.UpdateCLub.name);
          this.errors.name = validName.error;
          if(this.clubAdditionalInfoView) {
            clubType = validateSelect(this.UpdateCLub.type);
            this.errors.type = clubType.error;

            clubEmail = validateEmail(this.UpdateCLub.email);
            this.errors.email = clubEmail.error;

            clubAddress = validateAddressName(this.UpdateCLub.address);
            this.errors.club_info_address = clubAddress.error;

            clubCity = validateName(this.UpdateCLub.city,true);
            this.errors.club_info_city = clubCity.error;

            clubStateCode = validateSelect(this.UpdateCLub.state_code);
            this.errors.club_info_state_code = clubStateCode.error;

            clubZip = validateZip(this.UpdateCLub.zip);
            this.errors.club_info_zip = clubZip.error;
          }else {
            clubAgeDivision = validateSelect(this.UpdateCLub.age_division.toString());
            this.errors.age_division = clubAgeDivision.error;
          }
        // if(this.prop_fields && this.prop_fields.club_info != undefined && this.prop_fields.club_info.clubname != undefined)
        //   validName.valid = true;
        // if(this.prop_fields && this.prop_fields.club_info != undefined && this.prop_fields.club_info.club_type != undefined)
        //   clubType.valid = true;
        // if(this.prop_fields && this.prop_fields.club_info != undefined && this.prop_fields.club_info.email != undefined)
        //   clubEmail.valid = true;

        if (validName.valid && clubType.valid && clubEmail.valid && clubAddress.valid && clubCity.valid && clubStateCode.valid && clubZip.valid ||
            (!this.clubAdditionalInfoView && clubAgeDivision.valid && validName.valid)) {
            this.valid = true;
        }
        if(this.clubAdditionalInfoView) {
          //Shooting
          var clubVisibleType = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_visible_type != undefined && this.prop_fields.club_info.club_visible_type.required != undefined
              && this.prop_fields.club_info.club_visible_type.required) {
            clubVisibleType = validateSelect(this.UpdateCLub.visibility_type);
            this.errors.club_visible_type = clubVisibleType.error;
          }
          var clubType2 = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_type != undefined && this.prop_fields.club_info.club_type.required != undefined
              && this.prop_fields.club_info.club_type.required) {
            clubType2 = validateSelect(this.UpdateCLub.club_type);
            if (Array.isArray(this.UpdateCLub.club_type)) {
              if (this.UpdateCLub.club_type.length == 0) {
                clubType2.valid = false;
                clubType2.error = "This field is required";
              } else {
                clubType2.valid = true;
                clubType2.error = "";
              }
            }
            this.errors.club_type = clubType2.error;
          }
          var clubdisciplines = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_discipilines != undefined && this.prop_fields.club_info.club_discipilines.required != undefined
              && this.prop_fields.club_info.club_discipilines.required) {
            clubdisciplines = validateCheck(this.UpdateCLub.club_disciplines);
            this.errors.club_discipilines = clubdisciplines.error;
          }
          var clubAdminID = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_admin_member_id != undefined && this.prop_fields.club_info.club_admin_member_id.required != undefined
              && this.prop_fields.club_info.club_admin_member_id.required) {
            clubAdminID = validateName(this.UpdateCLub.club_admin_member_id.toString(),'');
            this.errors.club_admin_member_id = clubAdminID.error;
          }
          var clubAdminName = "";
          if (this.prop_fields.club_info != undefined
              && this.prop_fields.club_info.club_admin_name != undefined && this.prop_fields.club_info.club_admin_name.required != undefined
              && this.prop_fields.club_info.club_admin_name.required) {
            clubAdminName = validateName(this.UpdateCLub.club_admin_name);
            this.errors.club_admin_name = clubAdminName.error;
          }

          if (clubVisibleType != "" && clubType2 != "" && clubdisciplines != "" && clubAdminID != "" && clubAdminName != "") {
            if (clubVisibleType.valid && clubType2.valid && clubdisciplines.valid && clubAdminID.valid && clubAdminName.valid)
              this.valid = true;
            else
              this.valid = false;
          }

          //Shooting
        }
        return this.valid;
      },
      changeSameAddress(event){
        if(this.UpdateCLub.same_as_mailing_address){
            this.UpdateCLub.primary_contact.physical_address = this.UpdateCLub.primary_contact.address1;
            this.UpdateCLub.primary_contact.physical_city = this.UpdateCLub.primary_contact.city;
            this.UpdateCLub.primary_contact.physical_state_code = this.UpdateCLub.primary_contact.state_code;
            this.UpdateCLub.primary_contact.physical_zip = this.UpdateCLub.primary_contact.zip;
        }
      },
      validateTelinput(obj){
          this.validTelinput = obj.valid;
          console.log(obj);
          if(obj.valid){
              this.errors.mobilephone = '';
          }
      },
      validateContact(){
        this.errors = {};
        this.valid =false;
        var validAddress1 = "", validCity = "", validState = "", validCountry = "", validZip ="", validPhysicalZip = "";
        let validName = "";

        const validEmail = validateEmail(this.UpdateCLub.email,"E-Mail");
        this.errors.email = validEmail.error;

        const validRetypeEmail = validateEmail(this.UpdateCLub.retype_email, "Retype E-Mail");
        this.errors.retype_email = validRetypeEmail.error;
        if(this.clubAdditionalInfoView) {
          validAddress1 = validateAddressName(this.UpdateCLub.primary_contact.address1, "Club Address");
          this.errors.club_address = validAddress1.error;

          validCity = validateName(this.clubuserProfileData.city, "City",true);
          this.errors.club_city = validCity.error;

          validState = validateSelect(this.UpdateCLub.primary_contact.state_code, "State");
          this.errors.club_state = validState.error;

          validZip = validateZip(this.UpdateCLub.primary_contact.zip,this.UpdateCLub.primary_contact.country, "Zip/Postal Code");
          this.errors.club_zip = validZip.error;

          validPhysicalZip = validateNonMandatoryZip(this.UpdateCLub.primary_contact.physical_zip, "Zip/Postal Code");
          this.errors.club_physical_zip = validPhysicalZip.error;

          validName = {valid:true};
        }else{
          validAddress1 = validateAddressName(this.UpdateCLub.address, "Team Address");
          this.errors.club_address = validAddress1.error;

          validCity = validateName(this.UpdateCLub.city, "City",true);
          this.errors.city = validCity.error;

          validState = validateSelect(this.UpdateCLub.state_code, "State");
          this.errors.club_state = validState.error;

          validCountry = validateSelect(this.UpdateCLub.country, "Country");
          this.errors.country = validCountry.error;

          validZip = validateZip(this.UpdateCLub.zip,this.UpdateCLub.country, "Zip/Postal Code");
          this.errors.club_zip = validZip.error;

          validName = validateName(this.UpdateCLub.name);

          const validPhone1 = validatePhone(this.UpdateCLub.phone, this.validTelinput);
          this.errors.homephone = validPhone1.error;
          const validPhone2 = validatePhone2(this.UpdateCLub.phone_2, this.validTelinput2);
          this.errors.mobilephone = validPhone2.error;
        }

        const validPhone1 = validatePhone(this.UpdateCLub.primary_contact.phone_1, this.validTelinput);
        this.errors.mobilephone = validPhone1.error;

        if(
            this.errors.email == null &&
            this.errors.retype_email == null &&
            this.errors.club_address == null &&
            this.errors.club_city == null &&
            this.errors.club_state == null &&
            this.errors.club_zip == null &&
            this.errors.mobilephone == null &&
            this.errors.homephone == null &&
            this.errors.club_physical_zip == null
        ){
            if(this.UpdateCLub.email != this.UpdateCLub.retype_email) {
                this.errors.retype_email = 'The email id does not match';
                return false;
            }
            if(!validName.valid){
              this.alertMessage = "Team Name is missing.<br> Please fill update.";
              this.displayAlert = true;
              this.setDangerBg();
              return false;
            }
            if(this.clubAdminSearch.searched == true && this.UpdateCLub.club_admin_name == '' || this.UpdateCLub.club_admin_member_id == ''){
              const clubAdminName = validateName(this.UpdateCLub.club_admin_name);
              this.errors.club_admin_name = clubAdminName.error;
              this.errors.club_admin_member_id = "Please enter the member_id";
              this.errorUpdateKey++;
              return false;
            }
            return true;
        }

        return false;
      },
      validateTelinput2(obj){
        this.validTelinput2 = obj.valid;
        console.log(obj);
        if(obj.valid){
          this.errors.mobilephone = '';
        }
      },
      handleOk(bvModalEvt,id) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit(id)
      },
      handleSubmit(id) {
        // Exit when the form isn't valid
        if (this.validateInsurance()) {
          this.updateInsurance(id);
          this.$nextTick(() => {
            this.$bvModal.hide('updateClubInsurance')
          })
        }
      },
      validateInsurance() {
        this.errors = {}
        this.valid = false;
        console.log('------validate-------');
        console.log(this.updInsurance);
        const validFirstName = validateName(this.updInsurance.first_name);
        const validLastName = validateName(this.updInsurance.last_name);
        const validCompanyName = validateName(this.updInsurance.company_name);
        const validAddress1 = validateName(this.updInsurance.address1);
        const validStateCode = validateName(this.updInsurance.state_code);
        const validCity = validateName(this.updInsurance.city);
        const validZip = validateZip(this.updInsurance.zip);
        //const validHomePhoneCode = validateName(this.updInsurance.home_phone_country_code);
        const validHomePhone = validateName(this.updInsurance.home_phone);
        const validCertificateType = validateName(this.updInsurance.certificate_type);
        const validSpecificEventTitle = validateName(this.updInsurance.specific_event_title);
        const validSanctionedType = validateRadio(this.updInsurance.sanctioned_type);
        const validEventSanctionNo = validateName(this.updInsurance.event_sanction);
        const validEventSanctionStartDate = validateName(this.updInsurance.start_date);
        const validEventSanctionEndDate = validateName(this.updInsurance.end_date);
        const validTeamHasAnyAgreement = validateRadio(this.updInsurance.team_has_any_agreement);
        const validAdditionalInsurance = validateRadio(this.updInsurance.additional_insurance);
        const validNotACompany = validateCheck(this.notACompany);
        console.log('valid compay');
        console.log(validNotACompany);

        this.errors.first_name = validFirstName.error; //'Please Enter First Name';
        this.errors.last_name = validLastName.error; //'Please Enter Last Name';
        this.errors.company_name = validCompanyName.error;//'Please Company Name';
        this.errors.address1 = validAddress1.error;
        this.errors.state_code = validStateCode.error;
        this.errors.city = validCity.error;
        this.errors.zip = validZip.error;
        //this.errors.home_phone_country_code = validHomePhoneCode.error;
        this.errors.home_phone = validHomePhone.error;
        this.errors.certificate_type = validCertificateType.error;
        this.errors.specific_event_title = validSpecificEventTitle.error;
        this.errors.sanctioned_type = validSanctionedType.error;
        this.errors.start_date = validEventSanctionStartDate.error;
        this.errors.end_date = validEventSanctionEndDate.error;
        this.errors.event_sanction = validEventSanctionNo.error;
        this.errors.team_has_any_agreement = validTeamHasAnyAgreement.error;
        this.errors.additional_insurance = validAdditionalInsurance.error;

        if(this.notACompany && validFirstName.valid && validLastName.valid) {
            this.updInsurance.company_name = '';
        } else if(validCompanyName.valid && !this.notACompany) {
            this.updInsurance.first_name = '';
            this.updInsurance.last_name = '';
        }
        if(this.updInsurance.certificate_type == 'year') {
          this.errors.sanctioned_type = '';
        }
        if ((validCompanyName.valid || (validFirstName.valid && validLastName.valid)) && validAddress1.valid
            && validStateCode.valid && validCity.valid && validZip.valid && validHomePhone.valid
            && validCertificateType.valid
            && validTeamHasAnyAgreement.valid && validAdditionalInsurance.valid) {
          this.valid = true;

          if(this.notACompany && (!validFirstName.valid || !validLastName.valid)) {
            this.valid = false;
          } else if(!this.notACompany && !validCompanyName.valid) {
            this.valid = false;
          }
          if(this.updInsurance.certificate_type == 'specificEvent' && (!validSpecificEventTitle.valid || !validSanctionedType.valid || !validEventSanctionNo.valid || !validEventSanctionStartDate.valid || !validEventSanctionEndDate.valid)) {
            this.valid = false;
          } else {
            if(validEventSanctionStartDate.valid && !this.validateDate(this.updInsurance.start_date)) {
              this.valid = false;
              this.errors.start_date = "Invalid Date";
            }
            if(validEventSanctionEndDate.valid && !this.validateDate(this.updInsurance.end_date)) {
              this.valid = false;
              this.errors.end_date = "Invalid Date";
            }
            if(validEventSanctionEndDate.valid && this.validateDate(this.updInsurance.end_date) && (new Date(this.expiryDate).getTime() < new Date(this.updInsurance.end_date).getTime())) {
              this.valid = false;
              this.errors.end_date = "your selected date is exceed to club valid thru";
            }
            if(validEventSanctionEndDate.valid && this.validateDate(this.updInsurance.end_date) && new Date(this.updInsurance.end_date).getTime() < new Date(this.updInsurance.start_date).getTime()) {
              this.valid = false;
              this.errors.end_date = "Your seleted end date is earlier than start date";
            }
          }
          if(this.valid) {
            this.errors = {}
          }
        }

        return this.valid;
      },
      updateInsurance(id){
            axios.post(this.basePath + "api/update_club_insurance", this.updInsurance, {headers: this.adminHeaders})
                .then(
                    function (response) {
                      this.alertMessage = response.data.message;
                      this.displayAlert = true;
                      this.userProfileApi();
                    }.bind(this)
                ).catch(function (error) {
              console.log(error);
            });
      },
      updateFulfilled(id, val){
        let updateFulfilledData={
          id:id,
          fulfilled: val
        };
        axios.post(this.basePath + "api/update_club_insurance", updateFulfilledData, {headers: this.adminHeaders})
            .then(
                function(response) {
                  this.alertMessage = response.data.message;
                  this.displayAlert = true;
                  this.userProfileApi();
                }.bind(this)
            ).catch(function(error) {
          console.log(error);
        });
      },
      resetDisciplineData(){
        this.changeDisciplineData = {
          'sports_discipline':'',
          'id':'',
        };
      },
      dispDisciplineModal(item, index, target){
        this.changeDisciplineData.sports_discipline = item.sports_discipline;
        this.changeDisciplineData.id = item.id;
        this.DonationSportDisciplineDpDwn = (this.changeDisciplineData.sports_discipline!=0);
        this.$bvModal.show('changeDiscipline');
      },
      changeDiscipline(){
        if(this.changeDisciplineData.sports_discipline==0){
          return;
        }
        axios.post(this.basePath + "api/change_donation_discipline", this.changeDisciplineData, {headers: this.adminHeaders})
            .then(
                function(response) {
                  this.alertMessage = response.data.message;
                  this.displayAlert = true;
                  this.userProfileApi();
                }.bind(this)
            ).catch(function(error) {
          console.log(error);
        });
      },
      checkDiscipline(){
        this.DonationSportDisciplineDpDwn = (this.changeDisciplineData.sports_discipline!=0);
      },
      refreshCard(event){
        this.refreshProgress = true;
          let club = this.clubuserProfileData.id;
          this.displayAlert = false;
          axios.post(this.basePath+"api/club/refresh-card", { club: club })
          .then(res => {
              this.alertMessage = "Club Membership Card updated successfully";
              this.displayAlert = true;
              this.refreshProgress = false;
              this.updateLoggedUserInfo += 1;
              this.userProfileApi();
          })
          .catch(err => {
              console.log(err);
              this.refreshProgress = false;
          })
      },
      impersonate()
      {

        let cluburl = (this.subdomain == 'alliancefastpitch') ? "/team/dashboard" : "/club/dashboard";
        axios
            .post(process.env.VUE_APP_URL + "api/impersonate/club", {
              code: this.clubuserProfileData.club_uid
            })
            .then((response) => {
              if (response.data.status == "success") {
                localStorage.removeItem('admintoken');
                localStorage.removeItem('admin');
                localStorage.setItem("clubToken", response.data.data.token);
                localStorage.setItem(
                    "team",
                    JSON.stringify(response.data.data.team)
                );
                localStorage.setItem("ssoToken", response.data.data.sso_token);
                localStorage.setItem("time", moment().unix());
                console.log(localStorage.getItem('ssoToken'));
                let sso_cookie = response.data.data.sso_cookie;
                console.log(sso_cookie.name+', '+sso_cookie.token+', '+sso_cookie.time+ ";path="+sso_cookie.domain);
                //this.cookie.set(sso_cookie.name, sso_cookie.token, sso_cookie.time);
                let d = new Date();
                d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                let expires = "expires=" + d.toUTCString();
                document.cookie =
                    sso_cookie.name+"=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
                this.$router.push({ path: cluburl });

              }

            })
            .catch((error) => {

            });
      },
       addnote(){
        this.note = '';
        this.edit_note_id='';
        this.selectedNote='';
        this.notestatus=1;
    },
    
    storenote(){

      $('.note-error, .note-type-error').html("");
        var error_flag = 0;
        if(this.note == ""){
          $('.note-error').html("Please enter note");
          error_flag++;
        }

        if(this.selectedNote == ""){
          $('.note-type-error').html("Please enter note type");
          error_flag++;
        }

        if(error_flag) return;

        let form = {'note': this.note,'member_id': this.clubuserProfileData.member.id,'selectedNote':this.selectedNote,'notestatus':this.notestatus};

        this.noteProgress = true;
        axios.post(this.basePath+"api/Addnotes",form,{ headers:this.adminHeaders }).then(function (response){
          console.log(response)
                  this.alertMessage = "Note added successfully";
                  this.displayAlert = true;
                  this.$bvModal.hide("note-modal");
                  this.fetchnote();
                  this.addnote();
                  this.noteProgress = false;
                  $('html, body').animate({
                      scrollTop: $("#noteList").offset().top
                  }, 1000);
              }.bind(this)
              )
        .catch(function (error) {
          console.log(error);
        });
    },
    fetchnote(){
      this.regId = this.clubuserProfileData?.member?.id;
      axios.get(this.basePath+"api/Addnotes/member/"+ this.regId,{ headers:this.adminHeaders }).then(response => {
        this.userNote = response.data;
      })
      .catch(err => {
        console.log(err);
      })
    },
    editNote(item, button) {

      console.log('hi');
      console.log(item.id);
      if(item.id){
        this.note=item.note;
        console.log( this.note);
        this.member_id = item.member_id;
        console.log( this.member_id);
        this.selectedNote = item.note_type;
        console.log(this.selectedNote);
        this.notestatus = item.status;
        console.log( this.notestatus);
        this.edit_note_id = item.id;
        console.log( this.edit_note_id);
        this.edit_index = item;
         console.log( this.edit_index);
         console.log( this.edit_index.id);
      }
    },



    updatenote(){
        $('.note-error, .note-type-error').html("");
        var error_flag = 0;
        if(this.note.trim() == ""){
          $('.note-error').html("Please enter note");
          error_flag++;
        }

        if(this.selectedNote == ""){
          $('.note-type-error').html("Please enter note type");
          error_flag++;
        }

        if(error_flag) return;

        let form = {'note': this.note,'member_id': this.clubuserProfileData.member.id,'selectedNote':this.selectedNote,'notestatus':this.notestatus};

        this.noteProgress = true;
        axios.put(this.basePath+'api/Addnotes/'+this.edit_index.id,form,{ headers:this.adminHeaders }).then(function (response){
          this.alertMessage = "Note updated successfully";
          this.displayAlert = true;
          this.fetchnote();
          this.noteProgress = false
          this.$bvModal.hide("note-modal");
        }.bind(this))
        .catch(function (error) {
          console.log(error);
        });
    },

    deleteNote(id){
      this.displayAlert = false;
        this.$bvModal.msgBoxConfirm("Are you sure to delete this note ?" ,{
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          noCloseOnBackdrop: true,
           noCloseOnEsc: true,
          centered: true
        }).then(value => {

           console.log(value);
          if (String(value) == "true") {
             axios.delete(this.basePath+'api/Addnotes/'+id,{ headers:this.adminHeaders })
              .then(function (response) {
                console.log(response);
              this.alertMessage = "Note deleted Successfully";
              this.displayAlert = true;
              this.fetchnote();
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
          }
          })
           .catch(err => {
          console.log(err);
        });
         return false;
    },
    loadPurchaseHistory(){
      if (this.purchaseHistory.loaded) {
        return;
      }
      let memberId = this.clubuserProfileData.member.id;
      axios.get(this.basePath+"api/club/purchase-history/"+ memberId,{ headers:this.adminHeaders }).then(response => {
          this.purchaseHistory.data = response.data.data;
          this.purchaseHistory.loaded = true;
          this.purchaseHistory.totalRows = this.purchaseHistory.data.length;
          this.refreshCard();
      })
      .catch(err => {
          console.log(err);
      })
    },
    onFilteredPurchaseHistory(filteredItems,data) {
      this.purchaseHistory.totalRows = filteredItems.length;
      this.purchaseHistory.currentPage = 1;
    },
    customFilter(rows, filter){
      let transactionValid = false;
      let searchFlag = false;
      let startDateFlag = false;
      let endDateFlag = false;
      let compareDateFlag = false;

      let filterNames = rows.name + rows.product + rows.transaction_id;
      if(filter.search != null && filter.search.trim() != ''){
          searchFlag = true;
          if( filterNames.toLowerCase().includes(filter.search.trim().toLowerCase())){
              transactionValid =  true;
          }
      }
      
      if(filter.startDate != null && filter.startDate.trim() != '' && (filter.endDate.trim() == '' ) ){
          const start_date = moment(filter.startDate).format("MM/DD/YYYY");
          const filter_start_date = moment(rows.transaction_date).format("MM/DD/YYYY");
          
          if( moment(start_date).isSame(filter_start_date)  ){
              transactionValid =  true;
          }else if( moment(start_date).isBefore(filter_start_date) ){
              transactionValid =  true;
          }
          startDateFlag = true;
        }

        if(filter.endDate != null && filter.endDate.trim() != '' && filter.startDate.trim() == ''){
            const end_date = moment(filter.endDate).format("MM/DD/YYYY");
            const filter_end_date = moment(rows.transaction_date).format("MM/DD/YYYY");
            
            if( moment(end_date).isSame(filter_end_date)  ){
                transactionValid =  true;
            }else if( moment(end_date).isAfter(filter_end_date) ){
                transactionValid =  true;
            }
            endDateFlag = true;
        }

        if(filter.endDate.trim() != '' && filter.startDate.trim() != '' ){
            const filter_transaction_date = moment(rows.transaction_date).format("MM/DD/YYYY");
            const start_date = moment(filter.startDate).format("MM/DD/YYYY");
            const end_date = moment(filter.endDate).format("MM/DD/YYYY");

            if( moment(start_date).isSame(filter_transaction_date)  ){
                transactionValid =  true;
            }else if( moment(end_date).isSame(filter_transaction_date)  ){
                transactionValid =  true;
            }else if( moment(filter_transaction_date).isBetween(start_date, end_date)){
                transactionValid =  true;
            }
            compareDateFlag = true;
        }
        
        if( searchFlag || startDateFlag || endDateFlag || compareDateFlag){
            return transactionValid;
        }
        return  true;
      },
      getDiscipline()
      {
        axios.get(this.basePath+"api/getDisciplines")
            .then(response => {
              this.disciplines = response.data.data;
              let discipline_codes = Object.keys(this.disciplines);
              if(discipline_codes.length && this.disciplines_list.length == 0){
                discipline_codes.forEach(code => {
                  this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
                });
              }
              this.ageDivisionOPt = this.disciplines_list;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      transferRoster(id, dob){
        this.isUnderAgeDivision(dob);
        this.$refs['playerTransfer'].show()
        this.playerTransferForm.member_id = id;
      },
      validatePlayerTransfer(data){
      let validate = true;
      $('.league-error, .division-error, .team-error').html("");
        if(data.league == ""){
          $('#player-transfer-form .league-error').html("Please select the league");
          validate = false;
        }
        if(data.age_division == ""){
          $('#player-transfer-form .division-error').html("Please select the age division");
          validate = false;
        }
        if(data.team == ""){
          $('#player-transfer-form .team-error').html("Please select the team");
          validate = false;
        }
        return validate;
      },
      handlePlayerTransfer(evt)
      {
        evt.preventDefault();
        if(this.validatePlayerTransfer(this.playerTransferForm) == false) {
          return false;
        }
        axios.post(this.basePath + 'api/club/transferPlayer', this.playerTransferForm, {headers: this.adminHeaders})
            .then(function (response) {
              this.alertMessage = response.data.message;
              this.displayAlert = true;
              // this.loadRostersInfo();
              this.showLoader = false;
              this.$refs['playerTransfer'].hide()
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      },
      resetPlayerTransfer()
      {
        this.playerTransferForm.league = this.UpdateCLub.league;
        this.playerTransferForm.team = '';
        this.playerTransferForm.notify = '';
        this.playerTransferForm.member_id = '';
        this.playerTransferForm.age_division=this.UpdateCLub.age_division;

      },
      getLeagues()
      {
        axios.get(this.basePath+"api/active_league")
            .then(response => {
              this.teamLeague = response.data.data;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      getTeam()
      {
        let leagueId = this.playerTransferForm.league;
        let teams = this.teamLeague[leagueId].team;
        this.leagueTeam = [];
        console.log("league 2",teams);
        console.log("league 3",this.playerTransferForm.age_division);
        teams.forEach(code => {
          if(this.playerTransferForm.age_division == code.age_division){
            this.leagueTeam.push({ id: code.id, text: code.name });
          }
        });
        console.log("league 1",this.leagueTeam);
      },
      loadRostersInfo(){
        this.loaded=false;
        axios.get(this.basePath + 'api/admin/club-roster/'+this.teamId+'?currentOnly='+this.excludeExpiredRoster, {headers: this.adminHeaders})
            .then(function (response) {
              const resp = response.data.data;
              this.rostersInfo = resp.roster;
              this.loaded=true;
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      },
      checkTransferEnable(){
        axios.post(this.basePath + "api/get/playerTransfer/enable", this.updInsurance, {headers: this.adminHeaders})
            .then(
                function (response) {
                  const resp = response.data.data;
                  this.isTransferEnable = resp.flag;
                }.bind(this)
            ).catch(function (error) {
          console.log(error);
        });
      },
      removeStateCode(){
        this.UpdateCLub.state = '';
        this.UpdateCLub.state_code = '';
      },
      setDangerBg(){
        setTimeout(() => {
          $('#alert_box').children('div').removeClass('alert-success');
          $('#alert_box').children('div').addClass('alert-danger');
        }, 100);
        return false;
      },
      removeDangerBg(){
        setTimeout(() => {
          $('#alert_box').children('div').removeClass('alert-danger');
          $('#alert_box').children('div').addClass('alert-success');
        }, 100);
      },
      resetAlert(){
        this.displayAlert = false;
        this.removeDangerBg();
      },
      isUnderAgeDivision(birth_date){
        let age = this.getAgeSeasonWise(birth_date);
        this.divisions_list=[];
        this.teamOptions = this.getSiteProps('memberRegistration.team.fields.club_info.age_division.options');
        var valObj = this.teamOptions.filter(function (elem) {
          if (elem.validAge >= age) return elem.value;
        });
        if (valObj.length > 0) {
          age = Math.floor(age);
          valObj.forEach(code => {
            this.divisions_list.push({ id: code.id, text: code.value == "NA - N/A" ? "N/A" : code.value });
          });
        }
      },
      excludeRosterExpired(){
          this.loadRostersInfo();
      },
      changeInsuranceViewPrevious(event) {
        this.loadTeamInsurances();
      },
      changeSanctionViewPrevious(event) {
        this.loadClubSanctions();
      },
    },

    mounted(){
        if(this.subdomain == 'alliancefastpitch'){
          this.getDiscipline();
          this.checkTransferEnable();
          this.getLeagues();
          this.getCurrentSeason();
        }
        this.userProfileApi();
        this.getClubType();
        this.loadPropFields();

        if(this.subdomain == "shooting"){
            this.getClubDisciplines();
        }


    },
    updated() {
      if(this.subdomain == 'alliancefastpitch'){
        if(this.prop_fields) this.prop_fields_contact = this.prop_fields.contact;
        this.isAllianceFastPitch = true;
      }else{
        if(this.prop_fields) this.prop_fields_contact = this.prop_fields.club_contact;
      }
    },
    computed: {
      purchaseHistoryRows() {
        return this.purchaseHistory.data.length;
      }
    }
}
</script>
